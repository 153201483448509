:root {
  /* grid system units */
  --unit-zero: 0;
  --unit-zero-quarter: calc(100% / 12 * 0.25);
  --unit-zero-half: calc(100% / 12 * 0.5);
  --unit-zero-three-quarter: calc(100% / 12 * 0.75);

  --unit-one: calc(100% / 12);
  --unit-one-quarter: calc(100% / 12 * 1.25);
  --unit-one-half: calc(100% / 12 * 1.5);
  --unit-one-three-quarter: calc(100% / 12 * 1.75);

  --unit-two: calc(100% / 12 * 2);
  --unit-two-quarter: calc(100% / 12 * 2.25);
  --unit-two-half: calc(100% / 12 * 2.5);
  --unit-two-three-quarter: calc(100% / 12 * 2.75);

  --unit-three: calc(100% / 12 * 3);
  --unit-three-quarter: calc(100% / 12 * 3.25);
  --unit-three-half: calc(100% / 12 * 3.5);
  --unit-three-three-quarter: calc(100% / 12 * 3.75);

  --unit-four: calc(100% / 12 * 4);
  --unit-four-quarter: calc(100% / 12 * 4.25);
  --unit-four-half: calc(100% / 12 * 4.5);
  --unit-four-three-quarter: calc(100% / 12 * 4.75);

  --unit-five: calc(100% / 12 * 5);
  --unit-five-quarter: calc(100% / 12 * 5.25);
  --unit-five-half: calc(100% / 12 * 5.5);
  --unit-five-three-quarter: calc(100% / 12 * 5.75);

  --unit-six: calc(100% / 12 * 6);
  --unit-six-quarter: calc(100% / 12 * 6.25);
  --unit-six-half: calc(100% / 12 * 6.5);
  --unit-six-three-quarter: calc(100% / 12 * 6.75);

  --unit-seven: calc(100% / 12 * 7);
  --unit-seven-quarter: calc(100% / 12 * 7.25);
  --unit-seven-half: calc(100% / 12 * 7.5);
  --unit-seven-three-quarter: calc(100% / 12 * 7.75);

  --unit-eight: calc(100% / 12 * 8);
  --unit-eight-quarter: calc(100% / 12 * 8.25);
  --unit-eight-half: calc(100% / 12 * 8.5);
  --unit-eight-three-quarter: calc(100% / 12 * 8.75);

  --unit-nine: calc(100% / 12 * 9);
  --unit-nine-quarter: calc(100% / 12 * 9.25);
  --unit-nine-half: calc(100% / 12 * 9.5);
  --unit-nine-three-quarter: calc(100% / 12 * 9.75);

  --unit-ten: calc(100% / 12 * 10);
  --unit-ten-quarter: calc(100% / 12 * 10.25);
  --unit-ten-half: calc(100% / 12 * 10.5);
  --unit-ten-three-quarter: calc(100% / 12 * 10.75);

  --unit-eleven: calc(100% / 12 * 11);
  --unit-eleven-quarter: calc(100% / 12 * 11.25);
  --unit-eleven-half: calc(100% / 12 * 11.5);
  --unit-eleven-three-quarter: calc(100% / 12 * 11.75);

  --unit-twelve: 100%;
}

/* global
   ============================ */

html {
  font-size: 62.5%;
  overflow-x: hidden;
  min-height: 100%;
  width: 100%;
}
body {
  width: 100%;
  overflow-x: hidden;
  font-family: var(--maersk);
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: var(--light-grey);
  background-color: #ffffff;
  transition: background-color var(--transition-mode) var(--transition-speed);
}
.body--with-overlay::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(69, 69, 69, 0.1);
  z-index: 1;
}
.body--grey {
  background-color: var(--white-two);
}

/* links */
header, footer { 
  a,
  .a {
    text-decoration: none !important;
    color: var(--primary-two);
    font-family: var(--maersk-bold);
  }
  a:focus,
  .a:focus,
  a:focus,
  .a:focus {
    outline: 0;
    margin: 0 -8px -4px;
    padding: 0 8px 4px;
    background-color: var(--primary-two-hint);
  }
  a:hover,
  .a:hover {
    text-decoration: underline;
  }
  a:active,
  .a:active {
    outline: 0;
    color: var(--primary-two-muted);
    text-decoration: underline;
  }
  a:not([href]) {
    color: var(--neutral-grey);
    text-decoration: none;
  }

  a.go {
    display: inline-flex;
    align-items: center;
  }
  a.go::after {
    content: "";
    margin-left: 5px;
    display: block;
    height: 8px;
    width: 4px;
    min-width: 4px;
    background-image: var(--go);
  }
  a.go--back::after {
    content: none;
  }
  a.go--back::before {
    content: "";
    margin-right: 5px;
    display: block;
    height: 8px;
    width: 4px;
    min-width: 4px;
    background-image: var(--go);
    transform: rotate(180deg);
  }
}
button {
  padding: 0;
  color: inherit;
  background: none;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
button:focus {
  outline: none;
}

/* external link */
.external-link__anchor {
  color: var(--primary-two);
}
.external-link__anchor svg {
  width: 20px;
  height: 20px;
  fill: var(--primary-two);
}
.external-link svg {
  position: relative;
  bottom: -4px;
}
.rich-text .external-link a {
  margin-right: 3px;
}

/* lists */
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
dl,
dd {
  margin: 0;
}

.bullet-list .bullet-list-item {
  position: relative;
  padding-left: 9px;
}
.bullet-list .bullet-list-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 4px;
  height: 4px;
  background: var(--light-grey);
  border-radius: 50%;
}
[dir="rtl"] .bullet-list .bullet-list-item {
  padding-left: 0;
  padding-right: 9px;
}
[dir="rtl"] .bullet-list .bullet-list-item::before {
  left: auto;
  right: 0;
}

/* headings */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 400;
  margin: 0;
}

h1,
.h1 {
  font-family: var(--maersk-light);
  font-size: 5.8rem;
  line-height: 6.8rem;
}
h2,
.h2 {
  font-family: var(--maersk-light);
  font-size: 3.6rem;
  line-height: 5rem;
}
h3,
.h3 {
  font-family: var(--maersk-light);
  font-size: 2.8rem;
  line-height: 3.9rem;
}
h4,
.h4 {
  font-family: var(--maersk);
  font-size: 2.2rem;
  line-height: 3rem;
}
h5,
.h5 {
  font-family: var(--maersk-bold);
  font-size: 1.6rem;
  line-height: 2.7rem;
}
h6,
.h6 {
  font-family: var(--maersk-bold);
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.module-heading {
  margin-bottom: 40px;
  color: var(--primary-one);
}
.module-heading--card-grid {
  margin-top: 80px;
}

/* paragraph */
p {
  margin: 0 0 25px;
}

hr {
  width: 100%;
  height: 2px;
  border: 0;
  background-color: #d5d9e2;
  margin: 40px 0;
}

/* tone */
strong,
b,
.bold {
  font-family: var(--maersk-bold);
}

/* images */
img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

/* layout */
.wrapper {
  margin: 0 auto;
  width: var(--wrapper-width);
  padding: 0 var(--wrapper-margin-wide);
  max-width: 100%;
}
.page__main .wrapper {
  padding: 0;
}
.page-heading {
  margin: 40px auto;
  color: var(--primary-one);
  overflow-wrap: break-word;
}

/* 2 column layout */
.page {
  display: flex;
  flex-wrap: nowrap;
}
.page--left-rail {
  flex-direction: row-reverse;
}

.page__aside {
  margin-top: 60px;
  width: var(--unit-three-quarter);
  margin-right: calc(0 - var(--unit-zero-quarter));
}
.page--left-rail .page__aside {
  margin-left: calc(0px - var(--unit-zero-quarter));
  margin-right: 0;
}
.page-heading + div .page__aside {
  margin-top: 0;
}
.page__aside--floated {
  margin-top: -55px;
  z-index: 1;
}
.page__main {
  width: var(--unit-eight);
  margin: 40px var(--unit-zero-three-quarter) 0 0;
}
[dir="rtl"] .page__main {
  margin-right: 0;
  margin-left: var(--unit-zero-three-quarter);
}
.page--left-rail .page__main {
  margin: 60px 0 0 var(--unit-zero-three-quarter);
}
.page-heading + div .page__main {
  margin-top: 0;
}

.page__main h1,
.page__main h2,
.page__main h3,
.page__main h4,
.page__main h5 {
  margin-bottom: 15px;
  color: var(--primary-one);
}

/* tables */
table {
  /* minus 0.1px to fix the horizontal scrollbar always being shown */
  width: calc(100% - 0.1px);
  text-align: left;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 40px;
}
[dir="rtl"] table {
  text-align: right;
}
tbody {
  border-top: 2px solid var(--primary-one);
}
thead + tbody,
tbody:first-child {
  border-top: 0;
}
th {
  background: var(--primary-one);
  color: #ffffff;
  position: relative;
  font-family: var(--maersk);
  line-height: 2.5rem;
  font-weight: 400;
}
th::after {
  content: "";
  display: block;
  height: calc(100% - 20px);
  width: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--neutral-grey);
}
tbody th::after {
  display: none;
}
[dir="rtl"] th::after {
  right: auto;
  left: 0;
}
th:last-child::after {
  display: none;
}
tr {
  border: 1px solid #dee3e9;
  background-color: #ffffff;
  background-clip: padding-box;
}
tbody tr {
  border-color: #dee3e9;
  border-style: solid;
  border-width: 1px 1px 0px 1px;
}
tbody tr:last-child {
  border-width: 1px;
}
thead tr {
  border: 0;
}
tr:nth-child(even) {
  background-color: var(--white-three);
}
th,
td {
  padding: 8px;
  vertical-align: middle;
  background-clip: padding-box;
}
td {
  height: 61px;
  color: var(--dark-grey);
}
tfoot {
  border-top: 2px solid var(--primary-one);
}
tfoot tr {
  background-color: var(--mid-grey-muted);
}

/* definition lists */
dl {
  display: flex;
  flex-wrap: wrap;
  color: var(--primary-one);
  border: 1px solid var(--white);
  margin-bottom: 25px;
}
dl dt {
  width: 100%;
  padding: 8px 16px 7px;
  background-color: var(--white-three);
  font-family: var(--maersk-bold);
}
dl dd + dt {
  border-top: 1px solid var(--white);
}
dl dd {
  flex: 1;
  width: 100%;
  padding: 13px 16px 18px;
}

/* inline definition lists */
dl.inline dt {
  order: -1;
}
dl.inline dd {
  overflow-wrap: break-word;
}
dl.inline dt:not(:first-of-type),
dl.inline dd:not(:first-of-type) {
  border-left: 1px solid var(--white);
}
dl.inline dd + dt {
  border-top: 0;
}
dl.inline--two dt,
dl.inline--two dd {
  width: 50% !important;
}
dl.inline--three dt,
dl.inline--three dd {
  width: calc(100% / 3) !important;
}
dl.inline--four dt,
dl.inline--four dd {
  width: 25% !important;
}

/* separated column definition lists */
dl.columns {
  width: 100%;
  border: 0;
  display: block;
  column-count: 2;
  column-gap: 32px;
  padding-top: 1px;
}
dl.columns > div {
  display: inline-block;
  margin-top: -1px;
  border: 1px solid var(--white);
  width: 100%;
  break-inside: avoid;
}
dl.columns dt,
dl.columns dd {
  break-inside: avoid;
}

/* main container */
#main {
  transition: padding var(--transition-mode) var(--transition-speed);
}

/* helper classes
   ============================ */

.bg-grey {
  background-color: var(--white-three);
  color: var(--primary-one);
}

.show-on-focus {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  background-color: #eeeeee;
}
.show-on-focus:focus {
  height: auto;
  width: auto;
}

.hidden-accessible {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.equal-height {
  display: flex;
  flex-wrap: wrap;
}
.circular-item {
  border-radius: 50%;
}
.text-center {
  text-align: center;
}
.fit-image {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* dropshadow */
.ds-tilted {
  box-shadow: var(--shadow-tilted);
  transition: box-shadow var(--transition-speed) var(--transition-mode);
}
.ds-tilted:hover,
.ds-tilted:focus {
  box-shadow: var(--shadow-tilted-active);
}

.ds-downlight {
  box-shadow: var(--shadow-downlight);
  transition: box-shadow var(--transition-speed) var(--transition-mode);
}
.ds-downlight:hover,
.ds-downlight:focus {
  box-shadow: var(--shadow-downlight-active);
}

/* copy size */
.copy-large {
  font-size: 1.8rem;
  line-height: 2.4rem;
}
.copy-small {
  font-size: 1.4rem;
  line-height: 2.5rem;
}
.copy-extra-small {
  font-size: 1rem;
  line-height: 1.4rem;
}

/* text color */
.dark-blue {
  color: var(--primary-one);
}

/* horizontal scroll */
.h-scroll {
  margin: 40px 0;
  position: relative;
}

/* close button */
.close-button {
  display: block;
  width: 26px;
  height: 26px;
  position: relative;
}
.close-button::before,
.close-button::after {
  content: "";
  position: absolute;
  width: 26px;
  height: 2px;
  background-color: var(--dark-grey);
  transform: rotate(45deg);
  top: calc(50% - 1px);
  right: 0;
  margin: auto;
  transition: background-color var(--transition-speed) var(--transition-mode);
}
.close-button::after {
  transform: rotate(-45deg);
}
.close-button:hover::before,
.close-button:focus::before,
.close-button:hover::after,
.close-button:focus::after {
  background: var(--primary-two);
}

/* reset button */
.clear-icon__circle {
  stroke: var(--primary-two);
}
.clear-icon__path {
  fill: var(--primary-two);
}

/* loader overlay */
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(158, 158, 158, 0.1);
  z-index: 1;
}
.loader--hidden {
  display: none;
}
.loader__bar {
  width: 110px;
  height: 8px;
  position: relative;
}
.loader__bar::before {
  content: "";
  display: block;
  height: 100%;
  background: var(--primary-two);
  position: absolute;
  width: 0;
  animation-duration: 3.5s;
  animation-name: loader;
  animation-iteration-count: infinite;
}
[dir="rtl"] .loader__bar::before {
  animation-duration: 3.5s;
  animation-name: loaderRtl;
  animation-iteration-count: infinite;
}
.trace-listing__export .loader {
  align-items: flex-start;
}
.job-search .loader {
  align-items: flex-start;
}
.job-search .loader__bar {
  top: 200px;
}
.trace-listing__export .loader__bar {
  top: 50%;
  transform: translateY(-50%);
}
.trace-listing__export .loader__bar::before {
  animation-name: loaderMono;
  background: var(--light-grey);
}

/* animate on scroll */
.animate-on-scroll,
.animate-on-scroll__item {
  opacity: 0;
  transform: translateY(100px);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transition-duration: 0.7s;
  will-change: opacity, transform;
}
.animate-on-scroll--active,
.animate-on-scroll__item--active {
  opacity: 1;
  transform: translateY(0);
}
.no-js .animate-on-scroll,
.no-js .animate-on-scroll__item {
  opacity: 1;
  transform: translateY(0);
}

/* animate on load */
.animate-on-load {
  opacity: 0;
}
.animate-on-load__item {
  opacity: 0;
  transform: translateY(100px);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transition-duration: 3s;
  will-change: opacity, transform;
}
.animate-on-load__item.animate-on-load__item--down {
  transform: translateY(-100px);
}
.animate-on-load--active,
.animate-on-load__item--active,
.animate-on-load__item--down.animate-on-load__item--active {
  opacity: 1;
  transform: translateY(0);
}
.no-js .animate-on-load,
.no-js .animate-on-load__item {
  opacity: 1;
  transform: translateY(0);
}

/* RAG Status */
.status {
  display: inline-flex;
  align-items: center;
  margin: 0 8px 8px 0;
  padding: 3px 12px;
  border-radius: 20px;
  font-size: 1rem;
  line-height: 1.8rem;
  letter-spacing: 0.083rem;
  font-family: var(--maersk-bold);
  text-transform: uppercase;
  color: var(--primary-one);
  background-color: var(--neutral-grey-hint);
  white-space: nowrap;
  vertical-align: middle;
}
[dir="rtl"] .status {
  margin: 0 0 8px 8px;
}
.status:only-child,
[dir="rtl"] .status:only-child {
  margin: 0;
}
.status svg {
  width: auto;
  max-height: 2.4rem;
  margin: 0 4px 0 -10px;
}
[dir="rtl"] .status svg {
  margin: 0 -10px 0 4px;
}
.status path {
  fill: var(--primary-one);
}

.status--red {
  background-color: var(--red-hint);
}
.status--red path {
  fill: var(--red);
}
.status--amber {
  background-color: var(--soft-yellow-hint);
}
.status--amber path {
  fill: var(--soft-yellow);
}
.status--green {
  background-color: var(--green-hint);
}
.status--green path {
  fill: var(--green);
}
.status--black,
[dir="rtl"] .status--black {
  background-color: var(--primary-one);
  color: #ffffff;
  padding: 3px 13px;
}
.status--black path {
  fill: #ffffff;
}

/* Experience editor styles
   ============================ */
#scPageExtendersForm {
  position: relative;
  z-index: 3;
}
.scInsertionHandle {
  z-index: 9101 !important;
}
.scChromeDropDown img,
.scChromeToolbar img,
.sc_DropDownItem img {
  width: auto;
}
.scChromeCommand:focus {
  margin: 0;
}

/* responsive
   ============================ */

@media screen and (max-width: 1024px) {
  .wrapper {
    padding: 0 var(--wrapper-margin-narrow);
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .body--with-overlay::after {
    display: none;
  }

  /* lists */
  .bullet-list .bullet-list-item::before {
    top: 8px;
  }

  /* headings */
  h1,
  .h1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
  h2,
  .h2 {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }
  h3,
  .h3 {
    font-size: 2.6rem;
    line-height: 3.4rem;
  }
  h4,
  .h4 {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
  h5,
  .h5 {
    line-height: 2.4rem;
  }

  .module-heading {
    margin-bottom: 35px;
  }

  /* layout */
  .page {
    flex-direction: column;
  }
  .page--rail-first {
    flex-direction: column-reverse;
  }
  .page__aside,
  .page__main,
  .page--left-rail .page__aside,
  .page--left-rail .page__main {
    width: 100%;
    margin: 0;
  }
  .page-heading {
    margin: 30px auto 21px;
  }

  /* definition list */
  dt {
    padding: 10px 16px;
  }
  dd {
    padding: 16px 16px 21px;
  }
  dl.columns {
    column-count: 1;
  }

  /* helper classes
   ============================ */

  .animate-on-scroll,
  .animate-on-scroll__item {
    opacity: 1;
    transform: translateY(0);
  }

  /* copy size */
  .copy-small {
    line-height: 2rem;
  }

  /* have horizontal scrolling by default on mobile  */
  .h-scroll {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .h-scroll__container {
    display: flex;
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 1150px) and (max-height: 650px) {
  h1,
  .h1 {
    font-size: 5.2rem;
    line-height: 6.8rem;
  }
}
#ign_header, #ign_footer { 
  .button {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-two);
    padding: 14px 40px 17px;
    text-align: center;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-family: var(--maersk-bold);
    color: var(--primary-two);
    transition: color 0.2s var(--transition-mode),
      background-color 0.2s var(--transition-mode);
    z-index: 0;
  }
  .button[hidden] {
    display: none;
  }

  .button:hover {
    color: #ffffff;
    background-color: var(--primary-two);
    text-decoration: none;
  }

  .button:active {
    color: #ffffff;
    background-color: var(--primary-two-muted);
    text-decoration: none;
  }

  .button:focus {
    border-width: 3px;
    margin: 0;
    padding: 12px 38px 15px;
    background-color: #ffffff;
    color: var(--primary-two);
  }

  .button[disabled] {
    background-color: #bec4d0;
    border-color: #bec4d0;
    color: #ffffff;
  }
  .button--loading[disabled],
  .button--loading[disabled]:focus {
    background-color: var(--primary-two);
    border-color: var(--primary-two);
    color: var(--primary-two);
  }

  .button__loader {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .button--loading .button__loader {
    display: flex;
    transform: rotate(-90deg);
  }
  .button__loader svg {
    margin: 0 !important;
  }
  .button__loader circle {
    animation: button__loader 2.7s infinite cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .button + .button {
    margin-left: 16px;
  }

  [dir="rtl"] .button + .button {
    margin-left: 0;
    margin-right: 16px;
  }

  .button svg {
    margin-right: 10px;
  }

  [dir="rtl"] .button svg {
    margin-left: 10px;
    margin-right: 0;
  }

  .button path {
    fill: var(--primary-two);
    transition: fill 0.2s var(--transition-mode);
  }

  .button:hover path,
  .button:active path {
    fill: #ffffff;
  }

  .button:focus path {
    fill: var(--primary-two);
  }

  /* primary button */
  .button--primary,
  .button--primary:focus {
    background-color: var(--primary-two-hint);
  }

  /* small button */
  .button--small {
    font-size: 1.4rem;
    padding-top: 11px;
    padding-bottom: 12px;
  }

  .button--small:focus {
    padding-top: 9px;
    padding-bottom: 10px;
  }

  /* ios overrides */
  .ios .button:hover {
    color: #ffffff;
    background-color: var(--primary-two);
    text-decoration: none;
  }
  .ios .button:active {
    color: #ffffff;
    background-color: var(--primary-two-muted);
    text-decoration: none;
  }
  .ios .button:focus {
    border-width: 3px;
    padding: 12px 38px 15px;
    background-color: #ffffff;
    color: var(--primary-two);
  }

  .ios .button--primary,
  .ios .button--primary:focus {
    background-color: var(--primary-two-hint);
  }
  .ios .button--small:focus {
    padding-top: 9px;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 550px) {
    .button {
      width: 100%;
    }

    .button + .button,
    [dir="rtl"] .button + .button {
      margin: 16px 0 0;
    }
  }
}

@keyframes button__loader {
  0% {
    stroke-dasharray: 0 100 100 100;
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dasharray: 100 100 100 100;
    stroke-dashoffset: 0;
  }
}

.action {
  display: inline-flex;
  align-items: center;
  color: var(--primary-one);
  font-family: var(--maersk);
  transition: color var(--transition-mode) 0.2s;
}

.action__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 16px;
  border: 1px solid var(--primary-two);
  background-color: var(--primary-two-hint);
  color: var(--primary-two);
  transition: background-color 0.2s var(--transition-mode),
    border-color 0.2s var(--transition-mode), color 0.2s var(--transition-mode);
}

.action__icon svg {
  max-width: 18px;
  max-height: 18px;
}

.action__icon path {
  fill: var(--primary-two);
  transition: fill 0.2s var(--transition-mode);
}

.action__label {
  margin-left: 8px;
  font-size: 1.4rem;
  line-height: 2rem;
  white-space: nowrap;
}

[dir="rtl"] .action__label {
  margin-left: 0;
  margin-right: 8px;
}

.action:focus {
  color: var(--primary-two);
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.action:focus .action__icon {
  border-width: 2px;
}

.action:hover {
  color: var(--primary-two);
  text-decoration: none;
}

.action:hover .action__icon {
  background-color: var(--primary-two);
  color: #ffffff;
}

.action:hover .action__icon path {
  fill: #ffffff;
}

.action:active {
  text-decoration: none;
}

.action:active .action__icon {
  background-color: var(--primary-two-muted);
  border-color: var(--primary-two-muted);
}

.action:active .action__icon path {
  fill: #ffffff;
}

.action[disabled],
a.action:not([href]) {
  color: var(--neutral-grey);
}

.action[disabled] .action__icon,
a.action:not([href]) .action__icon {
  background-color: #bec4d0;
  border-color: #bec4d0;
}

.action[disabled] .action__icon path,
a.action:not([href]) .action__icon path {
  fill: #ffffff;
}

/* secondary action */
.action--secondary {
  position: relative;
  overflow: hidden;
}

.action--secondary .action__label {
  display: block;
  position: absolute;
  z-index: 1;
  bottom: 40px;
  margin: 0;
  padding: 3px 12px 4px;
  color: #ffffff;
  font-family: var(--maersk-bold);
  text-transform: uppercase;
  white-space: nowrap;
  background-color: var(--primary-one);
  left: 0;
  right: auto;
}
.action--secondary:hover {
  overflow: visible;
}
.action--secondary.action--reverse .action__label,
[dir="rtl"] .action--secondary .action__label {
  left: auto;
  right: 0;
}
[dir="rtl"] .action--secondary.action--reverse .action__label {
  left: 0;
  right: auto;
}

.action--secondary .action__label::after {
  content: "";
  position: absolute;
  bottom: -5px;
  border-top: 6px solid var(--primary-one);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  left: 11px;
  right: auto;
}
.action--secondary.action--reverse .action__label::after,
[dir="rtl"] .action--secondary .action__label::after {
  left: auto;
  right: 11px;
}
[dir="rtl"] .action--secondary.action--reverse .action__label::after {
  left: 11px;
  right: auto;
}

/* loading */
.action--loading[disabled] .action__icon,
.action--loading[disabled]:focus .action__icon {
  background-color: var(--primary-two);
  border-color: var(--primary-two);
}

.action__loader {
  display: none;
  position: absolute;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
}
.action--loading .action__loader {
  display: flex;
  transform: rotate(-90deg);
}
.action__loader svg {
  margin: 0 !important;
}
.action__loader circle {
  animation: action__loader 2.7s infinite cubic-bezier(0.25, 0.1, 0.25, 1);
}

@keyframes action__loader {
  0% {
    stroke-dasharray: 0 100 100 100;
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dasharray: 100 100 100 100;
    stroke-dashoffset: 0;
  }
}

input {
  background-color: transparent; /* reset submit inputs, allowing button classes to override this rule */
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="tel"],
select,
textarea {
  width: 100%;
  border: 1px solid var(--mid-grey-hint);
  background-color: var(--white-four);
  color: var(--primary-one);
  height: 48px;
  font-size: 1.6rem;
  line-height: 3.2rem;
  padding: 0 8px;
  border-radius: 4px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="tel"]:focus,
select:focus,
textarea:focus {
  border-color: var(--primary-one);
}

input[type="radio"]:focus,
input[type="checkbox"]:focus {
  border: 2px solid var(--primary-two);
}

input[type="text"]:disabled,
input[type="email"]:disabled,
input[type="search"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="date"]:disabled,
input[type="tel"]:disabled,
select:disabled,
textarea:disabled,
input[type="text"]:read-only,
input[type="email"]:read-only,
input[type="search"]:read-only,
input[type="number"]:read-only,
input[type="password"]:read-only,
input[type="date"]:read-only,
input[type="tel"]:read-only,
textarea:read-only {
  border-color: var(--neutral-grey-hint);
  background-color: var(--neutral-grey-hint);
  cursor: not-allowed;
}

/* grey area inputs */
.bg-grey input[type="text"],
.bg-grey input[type="email"],
.bg-grey input[type="search"],
.bg-grey input[type="number"],
.bg-grey input[type="password"],
.bg-grey input[type="date"],
.bg-grey input[type="tel"],
.bg-grey select,
.bg-grey textarea {
  background-color: #ffffff;
}

.bg-grey input[type="text"]:disabled,
.bg-grey input[type="email"]:disabled,
.bg-grey input[type="search"]:disabled,
.bg-grey input[type="number"]:disabled,
.bg-grey input[type="password"]:disabled,
.bg-grey input[type="date"]:disabled,
.bg-grey input[type="tel"]:disabled,
.bg-grey select:disabled,
.bg-grey textarea:disabled,
.bg-grey input[type="text"]:read-only,
.bg-grey input[type="email"]:read-only,
.bg-grey input[type="search"]:read-only,
.bg-grey input[type="number"]:read-only,
.bg-grey input[type="password"]:read-only,
.bg-grey input[type="date"]:read-only,
.bg-grey input[type="tel"]:read-only,
.bg-grey textarea:read-only {
  background-color: var(--neutral-grey-hint);
}

::placeholder {
  color: var(--neutral-grey);
}
:-ms-input-placeholder {
  color: var(--neutral-grey);
}

select::-ms-expand,
input::-ms-clear {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -2px;
  top: -2px;
}

textarea {
  padding: 8px 16px;
  height: auto;
}

input[type="search"],
input[type="radio"],
input[type="checkbox"],
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

select {
  padding: 5px 40px 5px 14px;
  color: var(--primary-one);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-image: var(--arrow-down);
}
[dir="rtl"] select {
  padding: 5px 14px 5px 40px;
  background-position: center left 16px;
}
select:focus {
  outline: none;
}
select[multiple] {
  height: auto;
  background-image: none;
}

/* replacement select */
.select {
  height: 48px;
}
.select:focus {
  outline: 0;
}
.select--active,
.select--focus {
  background-color: transparent;
}
.select--active select {
  background-image: var(--arrow-up);
}
.select--disabled {
  cursor: not-allowed;
}
.select__dropdown,
.select--show-above .select__dropdown {
  top: calc(100% - 1px);
  border: 0;
  box-shadow: var(--shadow-downlight);
  z-index: 2;
  bottom: auto;
}
.select__option {
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 14px 9px;
  min-height: 40px;
  color: var(--mid-grey);
  font-size: 1.4rem;
  line-height: 2rem;
}
.select__option--selected {
  background: none;
  color: var(--primary-one);
}
.select__option:hover {
  background: var(--primary-two-hint);
}
.select__option--disabled,
.select__option--disabled:hover {
  color: rgba(69, 69, 69, 0.4);
}

input[type="radio"],
input[type="checkbox"] {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 1px solid #aab4c3;
  background-color: #ffffff;
  vertical-align: bottom;
}
input[type="radio"]:disabled,
input[type="checkbox"]:disabled,
input[type="radio"]:disabled:checked,
input[type="checkbox"]:disabled:checked {
  border-color: #aab4c3;
  background-color: #eff0f2;
}
input[type="radio"] {
  border-radius: 100%;
}
input[type="radio"]:checked {
  background-size: 10px 8px;
}
input[type="radio"]:checked,
input[type="checkbox"]:checked {
  background-color: var(--primary-two);
  border-color: var(--primary-two);
  background-repeat: no-repeat;
  background-position: center;
  background-image: var(--tick);
}

input[type="radio"] + label,
input[type="checkbox"] + label,
input[type="radio"] + input[type="hidden"] + label,
input[type="checkbox"] + input[type="hidden"] + label {
  margin-left: 8px;
  color: var(--primary-one);
}
[dir="rtl"] input[type="radio"] + label,
[dir="rtl"] input[type="checkbox"] + label,
[dir="rtl"] input[type="radio"] + input[type="hidden"] + label,
[dir="rtl"] input[type="checkbox"] + input[type="hidden"] + label {
  margin-left: 0;
  margin-right: 8px;
}

label input[type="radio"],
label input[type="checkbox"] {
  margin-right: 8px;
  color: var(--primary-one);
}
[dir="rtl"] label input[type="radio"],
[dir="rtl"] label input[type="checkbox"] {
  margin-right: 0;
  margin-left: 8px;
}

label svg {
  margin-right: 8px;
  vertical-align: middle;
  height: 24px;
  width: 24px;
}
[dir="rtl"] label svg {
  margin-right: 0;
  margin-left: 8px;
}

/* custom forms */
.form__section-title {
  margin-bottom: 40px;
  font-size: 2.4rem;
  color: var(--primary-one);
}
.form__section {
  margin-bottom: 40px;
}
.form__section:last-of-type {
  margin-bottom: 0;
}
.form__required {
  margin-bottom: 40px;
  font-size: 1.4rem;
  color: var(--mid-grey);
  font-family: var(--maersk-bold);
}
.form__row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  gap: 0 16px;
}
.form__row--border {
  padding-top: 32px;
  padding-bottom: 16px;
  border-top: 1px solid var(--mid-grey);
  border-bottom: 1px solid var(--mid-grey);
  margin-bottom: 32px;
}
.form__row--border + .form__row--border {
  border-top: 0;
  padding-top: 0;
}
.form__row--empty {
  color: var(--mid-grey);
  font-family: var(--maersk-bold);
  margin-top: 48px;
  margin-bottom: 80px;
}
.form__field {
  flex: 1;
  position: relative;
  margin-bottom: 40px;
}
.form__field--half {
  min-width: calc(50% - 8px);
  max-width: calc(50% - 8px);
}
.form__field--phone {
  flex: 3;
  position: relative;
  max-width: none !important;
}
.form__field .field-validation-valid,
.form__field .field-validation-error {
  margin-bottom: 0 !important;
}
.form__field input[type="password"] {
  padding-right: 50px;
}
.form__label {
  display: inline-block;
  margin-bottom: 7px;
  color: var(--mid-grey);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 16px);
}
.form--hidden,
.form__field--hidden {
  display: none;
}

.form__tooltip {
  position: relative;
}
.form__tooltip-body {
  display: none;
  width: 290px;
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translate(100%, -50%);
  box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
  background: #ffffff;
  z-index: 1;
}
.form__tooltip-body::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-width: 10px;
  transform: translate(-100%, -50%) rotate(-90deg);
  border-style: solid;
  border-color: transparent transparent #ffffff;
}
.form__tooltip-body--active,
.form__tooltip-body--hover {
  display: block;
}
.form__tooltip-inner {
  padding: 39px 26px;
}
.form__tip {
  max-width: 220px;
  margin-bottom: 22px;
  font-size: 1.6rem;
  font-family: var(--maersk-bold);
  color: #737373;
  line-height: 2.2rem;
}
.form__tip:last-of-type {
  margin-bottom: 0;
}
.form__tooltip-trigger {
  height: 46px;
  width: 49px;
  position: absolute;
  top: 1px;
  right: 0;
  border-left: 1px solid var(--mid-grey-hint);
}
.form__tooltip-trigger:focus .info__path {
  fill: var(--primary-two);
}
.form__tooltip-close {
  position: absolute;
  top: 21px;
  right: 24px;
}
.form__tooltip-close:focus {
  outline: 1px dotted;
}
.form__email {
  font-size: 1.8rem;
  color: var(--dark-grey);
}
.form__email svg {
  margin-right: 5px;
  width: 20px;
  max-height: 13px;
}
.form__tooltip-ico {
  display: block;
  width: 18px;
  height: 18px;
  margin: 0 auto;
}
.form__password {
  font-size: 1.4rem;
  color: var(--dark-grey);
}
.form__password-rep {
  font-size: 1rem;
  font-weight: 700;
}
.form__change-password {
  margin-top: 11px;
}
.form .info__path {
  fill: var(--primary-one);
}
.form__error {
  font-size: 1.4rem;
  color: var(--red);
}

/* reset password */
.password {
  max-width: 600px;
  padding: 50px 100px;
  margin: 100px auto;
  box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
  background: #ffffff;
}
.password .form__section-title {
  text-align: center;
}
.password .form__submit {
  display: block;
  margin: 50px auto 0;
}

/* sitecore forms */
form[id^="fxb_"] {
  margin: 0 auto 40px;
  width: var(--wrapper-width);
  padding: 0 var(--wrapper-margin-wide);
  max-width: 100%;
}
.wrapper form[id^="fxb_"] {
  padding: 0;
}
form[id^="fxb_"] .half {
  display: inline-block;
  width: calc(50% - 16px);
  margin-right: 10px;
  vertical-align: top;
  padding-right: 0;
}
form[id^="fxb_"] .required::after {
  content: "*";
}
form[id^="fxb_"] h1,
form[id^="fxb_"] h2,
form[id^="fxb_"] h3 {
  color: var(--primary-one);
  margin-top: 30px;
}
form[id^="fxb_"] p {
  margin-bottom: 40px;
}
form[id^="fxb_"] input[type="text"],
form[id^="fxb_"] input[type="email"],
form[id^="fxb_"] input[type="search"],
form[id^="fxb_"] input[type="number"],
form[id^="fxb_"] input[type="password"],
form[id^="fxb_"] input[type="date"],
form[id^="fxb_"] input[type="tel"],
form[id^="fxb_"] select {
  max-width: 416px;
}
form[id^="fxb_"] textarea {
  height: 130px;
}
form[id^="fxb_"] label {
  display: flex;
  align-items: flex-start;
  margin-bottom: 7px;
  color: var(--mid-grey);
  white-space: normal;
  text-overflow: ellipsis;
  max-width: calc(100% - 16px);
}
form[id^="fxb_"] .message {
  margin-bottom: 40px;
}
form[id^="fxb_"] .field-validation-valid,
form[id^="fxb_"] .field-validation-error {
  display: block;
  margin-bottom: 40px;
}
form[id^="fxb_"] input[type="submit"] {
  cursor: pointer;
  margin-top: 20px;
  display: inline-flex;
  justify-content: center;
  border: 1px solid var(--primary-two);
  padding: 14px 40px 17px;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-family: var(--maersk-bold);
  color: var(--primary-two);
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  transition: color var(--transition-mode) 0.2s,
    background-color var(--transition-mode) 0.2s;
  z-index: 0;
}
form[id^="fxb_"] input[type="submit"]:hover {
  color: #ffffff;
  background-color: var(--primary-two);
  text-decoration: none;
}
form[id^="fxb_"] input[type="submit"]:active {
  color: #ffffff;
  background-color: var(--primary-two-muted);
}
form[id^="fxb_"] input[type="submit"]:focus {
  border-width: 3px;
  padding: 12px 38px 15px;
}
form[id^="fxb_"] input[type="submit"][disabled] {
  background-color: #bec4d0;
  border-color: #bec4d0;
  color: #ffffff;
}
form[id^="fxb_"] .buttons input[type="submit"] {
  margin-top: 0;
}

/* error state */
.field-validation-error {
  color: var(--red);
}
.input-validation-error {
  border-color: var(--red) !important;
}
input[type="checkbox"].input-validation-error,
input[type="radio"].input-validation-error {
  background-color: var(--red-hint);
}

input[type="checkbox"]:checked.input-validation-error,
input[type="radio"]:checked.input-validation-error {
  background-color: var(--red);
}

/* tags */
.tag {
  display: inline-flex;
  align-items: center;
  margin: 0 8px 8px 0;
  padding: 8px;
  background-color: var(--white-four);
  border: 1px solid var(--mid-grey-hint);
  border-radius: 4px;
  transition: border-color 0.3s;
}
.tag--error {
  border-color: var(--red);
}
.tag--focused,
.bg-grey .tag--focused {
  border-color: var(--red);
  background-color: var(--red-hint);
}
.tag__value,
input.tag__value {
  height: auto;
  background-color: transparent !important;
  line-height: normal;
  padding: 0;
  border-radius: 0;
  border: 0;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-family: var(--maersk-bold);
}
.tag__close {
  margin-left: 5px;
  border-left: 1px solid var(--mid-grey-hint);
  width: 24px;
  min-width: 24px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 16px;
  background-image: var(--close);
}
[dir="rtl"] .tag__close {
  margin-left: 0;
  margin-right: 5px;
  border-left: 0;
  border-right: 1px solid var(--mid-grey-hint);
}

/* Form action buttons */
.buttons {
  margin: 40px 0;
}
.buttons--right {
  text-align: right;
}
[dir="rtl"] .buttons--right {
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .form__tooltip-body {
    max-width: 230px;
  }
  .form__tip {
    max-width: 165px;
  }

  form[id^="fxb_"] {
    padding: 0 var(--wrapper-margin-narrow);
  }
}

@media screen and (max-width: 768px) {
  .form {
    width: 100%;
  }
  .form__tooltip-body {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    max-width: initial;
    width: 100%;
    height: calc(100% - 60px);
    top: 60px;
    left: 0;
    transform: none;
    background: rgba(55, 55, 55, 0.2);
  }
  .form__tooltip-body--active,
  .form__tooltip-body--hover {
    display: flex;
  }
  .form__tooltip-body::after {
    left: auto;
    right: 0;
    transform: translate(100%, -50%) rotate(90deg);
  }
  .form__tooltip-body--rev {
    right: auto;
  }
  .form__tooltip-body--rev::after {
    right: auto;
    left: 0;
    transform: translate(-100%, -50%) rotate(-90deg);
  }
  .form__tooltip-inner {
    width: calc(100% - 48px);
    margin: 0 auto;
    position: relative;
    background: #ffffff;
    box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
  }
  .form__tip {
    max-width: initial;
  }
  .form__error {
    width: 100%;
    margin-top: 5px;
  }

  /* reset password */
  .password {
    padding: 39px 20px;
    margin: 30px auto;
    width: calc(100% - 40px);
  }
  .password .form__submit {
    margin: 10px auto 0;
  }

  /* sitecore forms */
  form[id^="fxb_"] h1,
  form[id^="fxb_"] h2,
  form[id^="fxb_"] h3 {
    padding: 10px 0;
    margin: 0;
  }
  form[id^="fxb_"] .half {
    width: 100%;
    margin: 0 0 15px;
  }
}

@media screen and (max-width: 550px) {
  .form__section {
    margin-bottom: 27px;
  }
  .form__row {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .form__field {
    margin: 0 0 32px;
    width: 100%;
    max-width: 100%;
  }
  .form__field--half {
    min-width: 100%;
    max-width: 100%;
  }

  form[id^="fxb_"] input[type="submit"] {
    width: 100%;
  }
  form[id^="fxb_"] .buttons .button + input[type="submit"] {
    margin: 16px 0 0;
  }
}

.checkbox-dropdown {
  position: relative;
  height: 48px;
}
.checkbox-dropdown__pseudo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--primary-one);
  font-size: 1.6rem;
  line-height: 3.2rem;
  padding: 5px 40px 5px 14px;
  border-radius: 4px;
  border: 1px solid var(--mid-grey-hint);
  background-color: var(--white-four);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-image: var(--arrow-down);
}
.checkbox-dropdown--active .checkbox-dropdown__pseudo {
  background-image: var(--arrow-up);
}
[dir="rtl"] .checkbox-dropdown__pseudo {
  padding: 5px 14px 5px 40px;
  background-position: center left 16px;
}
.checkbox-dropdown__dropdown {
  display: none;
  cursor: default;
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 50vh;
  overflow: auto;
  background: #ffffff;
  top: calc(100% - 1px);
  border: 0;
  box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
  color: var(--dark-grey);
  z-index: 2;
  bottom: auto;
  -ms-overflow-x: hidden;
}
.checkbox-dropdown--active .checkbox-dropdown__dropdown {
  display: block;
}
.checkbox-dropdown__dropdown:focus {
  outline: none;
}
.checkbox-dropdown__option {
  position: relative;
}
.checkbox-dropdown__option--selected {
  background: none;
  color: var(--primary-one);
}
.checkbox-dropdown__option:hover {
  background: var(--primary-two-hint);
}
.checkbox-dropdown__option--disabled,
.checkbox-dropdown__option--disabled:hover {
  color: rgba(69, 69, 69, 0.4);
}
.checkbox-dropdown__option--hidden {
  display: none;
}
.checkbox-dropdown__option label {
  display: flex;
  gap: 0 8px;
  justify-content: space-between;
  align-items: center;
  padding: 7px 14px 9px;
  min-height: 40px;
  color: var(--mid-grey);
  font-size: 1.4rem;
  line-height: 2rem;
}
.checkbox-dropdown__option input {
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-size: 12px 8px;
  margin: 0 !important;
}

.bg-grey .checkbox-dropdown__pseudo {
  background-color: #ffffff;
}

.typeahead {
  position: relative;
}
.typeahead .ui-autocomplete.ui-widget.ui-widget-content {
  position: absolute;
  left: 0 !important;
  max-width: 100%;
  max-height: 430px;
  transform: none;
  overflow-y: auto;
  top: calc(100% - 1px) !important;
  border: 0;
  box-shadow: var(--shadow-downlight);
  z-index: 2;
}
.typeahead .ui-menu .ui-menu-item {
  position: relative;
  overflow: hidden;
}
.typeahead .ui-menu .ui-menu-item.ui-state-disabled {
  opacity: 1;
}
.typeahead .ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 14px 9px;
  min-height: 40px;
  color: var(--mid-grey);
  font-size: 1.4rem;
  line-height: 2rem;
}
.typeahead .ui-state-active,
.typeahead .ui-widget-content .ui-state-active {
  border-radius: 0;
  color: var(--primary-one);
  background: var(--primary-two-hint);
  border: 0;
  margin: 0;
}

.typeahead--select input[type="text"] {
  background-color: transparent;
  cursor: default;
  position: absolute;
  padding: 5px 40px 5px 14px;
  left: 0;
  top: 0;
}
.typeahead--select input[type="text"]::placeholder {
  color: transparent;
}

.typeahead--select.typeahead--open input[type="text"] {
  background-color: var(--white-four);
  background-image: var(--arrow-up);
  background-repeat: no-repeat;
  background-position: center right 16px;
}
[dir="rtl"] .typeahead--open input[type="text"] {
  padding: 5px 14px 5px 40px;
  background-position: center left 16px;
}
.typeahead--open input[type="text"]::placeholder {
  color: var(--neutral-grey);
}

/* Loader */
.typeahead__loader {
  display: none;
  position: absolute;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: 15px;
  transform: translateY(-50%) rotate(-90deg);
}

.typeahead--loading .typeahead__loader {
  display: flex;
}

.typeahead__loader svg {
  margin: 0 !important;
}

.typeahead__loader svg circle {
  stroke: #d5d9e2;
}

.typeahead__loader circle {
  animation: typeahead__loader 2.7s infinite cubic-bezier(0.25, 0.1, 0.25, 1);
}

@keyframes typeahead__loader {
  0% {
    stroke-dasharray: 0 100 100 100;
    stroke-dashoffset: 100;
  }

  100% {
    stroke-dasharray: 100 100 100 100;
    stroke-dashoffset: 0;
  }
}

.input-file__input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.input-file__pseudo {
  border-style: dashed;
}
.input-file__list {
  margin-top: 4px;
}
.input-file__tag {
  max-width: 100%;
}
.input-file__value {
  display: flex;
  max-width: calc(100% - 29px);
}
.input-file__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (max-width: 550px) {
  .input-file__tag {
    max-width: 80vw;
  }
}

.input-number {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-number__control {
  width: 20px;
  height: 20px;
  min-width: 20px;
}
.input-number__control[disabled] path {
  fill: #bec4d0;
}
.input-number__input {
  margin: 0 4px;
  padding: 0 !important;
  flex: 1;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .input-number__control {
    display: none;
  }
  .input-number__input {
    margin: 0;
  }
}

@keyframes loader {
  0% {
    left: 0;
    width: 0;
  }

  11% {
    left: auto;
    right: auto;
    width: 100%;
    background: var(--primary-two);
  }
  12% {
    right: 0;
  }

  24% {
    right: 0;
    width: 0;
    background: var(--primary-one);
  }
  25% {
    right: auto;
  }

  37% {
    right: auto;
    width: 100%;
    background: var(--primary-one);
  }
  38% {
    right: 0;
  }

  50% {
    right: 0;
    width: 0;
    background: var(--primary-three);
  }
  51% {
    right: auto;
  }

  63% {
    right: auto;
    width: 100%;
    background: var(--primary-three);
  }
  64% {
    right: 0;
  }

  76% {
    right: 0;
    width: 0;
    background: var(--primary-four);
  }
  77% {
    right: auto;
  }

  89% {
    right: auto;
    width: 100%;
    background: var(--primary-four);
  }
  90% {
    right: 0;
  }

  99% {
    right: 0;
    width: 0;
    background: var(--primary-two);
  }
  100% {
    right: auto;
  }
}

@keyframes loaderRtl {
  0% {
    right: 0;
    width: 0;
  }

  11% {
    right: auto;
    left: auto;
    width: 100%;
    background: var(--primary-two);
  }
  12% {
    left: 0;
  }

  24% {
    left: 0;
    width: 0;
    background: var(--primary-one);
  }
  25% {
    left: auto;
  }

  37% {
    left: auto;
    width: 100%;
    background: var(--primary-one);
  }
  38% {
    left: 0;
  }

  50% {
    left: 0;
    width: 0;
    background: var(--primary-three);
  }
  51% {
    left: auto;
  }

  63% {
    left: auto;
    width: 100%;
    background: var(--primary-three);
  }
  64% {
    left: 0;
  }

  76% {
    left: 0;
    width: 0;
    background: var(--primary-four);
  }
  77% {
    left: auto;
  }

  89% {
    left: auto;
    width: 100%;
    background: var(--primary-four);
  }
  90% {
    left: 0;
  }

  99% {
    left: 0;
    width: 0;
    background: var(--primary-two);
  }
  100% {
    left: auto;
  }
}

@keyframes loaderMono {
  0% {
    left: 0;
    width: 0;
  }

  11% {
    left: auto;
    right: auto;
    width: 100%;
  }
  12% {
    right: 0;
  }

  24% {
    right: 0;
    width: 0;
  }
  25% {
    right: auto;
  }

  37% {
    right: auto;
    width: 100%;
  }
  38% {
    right: 0;
  }

  50% {
    right: 0;
    width: 0;
  }
  51% {
    right: auto;
  }

  63% {
    right: auto;
    width: 100%;
  }
  64% {
    right: 0;
  }

  76% {
    right: 0;
    width: 0;
  }
  77% {
    right: auto;
  }

  89% {
    right: auto;
    width: 100%;
  }
  90% {
    right: 0;
  }

  99% {
    right: 0;
    width: 0;
  }
  100% {
    right: auto;
  }
}

@keyframes scrollHand {
  0% {
    transform: rotate(-332deg) translate(48px, 5px);
  }

  25% {
    transform: rotate(-332deg) translate(26px, 17px);
  }

  100% {
    transform: rotate(-332deg) translate(48px, 5px);
  }
}

@keyframes scrollHandFront {
  0% {
    width: 30px;
  }

  25% {
    width: 10px;
  }

  100% {
    width: 30px;
  }
}

@keyframes scrollHandBack {
  0% {
    width: 40px;
    transform: translateX(-10px);
  }

  25% {
    width: 65px;
    transform: translateX(-35px);
  }

  100% {
    width: 40px;
    transform: translateX(-10px);
  }
}

@keyframes scrollHandRtl {
  0% {
    transform: rotate(-332deg) translate(48px, 5px);
  }

  25% {
    transform: rotate(-332deg) translate(70px, -7px);
  }

  100% {
    transform: rotate(-332deg) translate(48px, 5px);
  }
}

@keyframes scrollHandFrontRtl {
  0% {
    width: 30px;
  }

  25% {
    width: 50px;
  }

  100% {
    width: 30px;
  }
}

@keyframes scrollHandBackRtl {
  0% {
    width: 40px;
    transform: translateX(-10px);
  }

  25% {
    width: 25px;
    transform: translateX(5px);
  }

  100% {
    width: 40px;
    transform: translateX(-10px);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal {
  position: fixed;
  z-index: 5;
}
.modal--hidden {
  display: none;
}

.modal__bg {
  margin: 0;
  background-color: rgba(55, 55, 55, 0.2);
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal__container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--wrapper-width);
  max-width: 864px;
  max-height: 70%;
  background: #ffffff;
  overflow-y: auto;
}
[dir="rtl"] .modal__container {
  left: 0;
  right: 50%;
  transform: translate(50%, -50%);
}

.modal__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--primary-one);
  color: #ffffff;
  padding: 16px 16px 16px 32px;
}
[dir="rtl"] .modal__header {
  padding: 16px 32px 16px 16px;
}
.modal__header h2 {
  margin: 0 !important;
  color: #ffffff !important;
}
.modal__header .modal__close {
  display: flex;
  margin-left: auto;
}
[dir="rtl"] .modal__header .modal__close {
  margin-left: 0;
  margin-right: auto;
}
.modal__content {
  position: relative;
  padding: 56px 32px 32px;
}

.modal__footer {
  border-top: 1px solid var(--mid-grey-muted);
  padding: 39px 32px 56px;
}

@media screen and (max-width: 1024px) {
  .modal__container {
    width: calc(100% - (2 * var(--wrapper-margin-narrow)));
  }
}

@media screen and (max-width: 768px) {
  .modal__header,
  [dir="rtl"] .modal__header {
    padding: 16px;
  }
  .modal__content {
    padding: 17px 16px 32px;
  }
  .modal__footer {
    padding: 39px 16px 40px;
  }
}

@media print {
  .modal.print-focus .modal__container {
    position: static;
    transform: none;
    width: 100%;
    max-width: none;
    max-height: none;
    overflow-y: visible;
    top: 0;
    left: 0;
  }
  .modal.print-focus .modal__header {
    display: flex;
  }
  .modal.print-focus .modal__header :not(.modal__close) {
    display: block;
  }
  .modal.print-focus .info-modal__print {
    display: none !important;
  }
  .modal.print-focus,
  .modal.print-focus .modal__bg {
    position: relative;
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--white-three);
  z-index: 3;
  flex-direction: column;
}
.overlay--open {
  display: flex;
}

.header ~ #main .overlay,
.header--large.header--slim ~ #main .overlay {
  margin-top: 90px;
}
.header--alert ~ #main .overlay,
.header--large.header--slim.header--alert ~ #main .overlay {
  margin-top: 130px;
}
.header--large ~ #main .overlay {
  margin-top: 150px;
}
.header--large.header--alert ~ #main .overlay {
  margin-top: 190px;
}
.header--large.header--alert-open ~ #main .overlay {
  margin-top: 255px;
}
.header--alert-open ~ #main .overlay,
.header--large.header--alert-open.header--slim ~ #main .overlay {
  margin-top: 195px;
}

.overlay__content {
  flex: 1;
  overflow-y: auto;
  padding: 40px 0;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .header ~ #main .overlay,
  .header--large ~ #main .overlay,
  .header--large.header--slim ~ #main .overlay {
    margin-top: 60px;
  }
  .header--alert ~ #main .overlay,
  .header--large.header--alert ~ #main .overlay,
  .header--large.header--slim.header--alert ~ #main .overlay {
    margin-top: 120px;
  }
}

.message {
  display: flex;
  align-items: flex-start;
  border-left: 2px solid var(--soft-yellow);
  background: var(--soft-yellow-hint);
  color: var(--primary-one);
  font-size: 1.6rem;
  line-height: 2.5rem;
}
[dir="rtl"] .message {
  border-left: 0;
  border-right: 2px solid var(--soft-yellow);
}

.message--hidden {
  display: none;
}
.message--fixed {
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
}
.body--generic-filters-open .message--fixed {
  padding-left: 300px;
}
[dir="rtl"] .body--generic-filters-open .message--fixed {
  padding-left: 0;
  padding-right: 300px;
}

.message--green,
.message--success {
  background-color: var(--green-hint);
  border-color: var(--green);
}
.message--red,
.message--error {
  background-color: var(--red-hint);
  border-color: var(--red);
}

.message--neutral {
  background-color: var(--white-three);
  border-color: var(--white-three);
}

.message__icon,
.message__close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.message__icon {
  margin: 15px 0 0 12px;
}
[dir="rtl"] .message__icon {
  margin: 15px 12px 0 0;
}
.message__text {
  flex: 1;
  padding: 11px 10px 12px;
}
.message__text p {
  margin-bottom: 0;
}
.message__close {
  margin-top: 12px;
  width: 40px;
}
.message .info__path {
  fill: var(--soft-yellow);
}
.message .close__path {
  fill: var(--mid-grey);
}
.message--green .info__path,
.message--green .close__path,
.message--success .info__path,
.message--success .close__path {
  fill: var(--green);
}
.message--red .info__path,
.message--red .close__path,
.message--error .info__path,
.message--error .close__path {
  fill: var(--red);
}

.message__text a,
.message__text .a,
.message__text button {
  color: var(--primary-one);
  text-decoration: underline;
  font-family: var(--maersk-bold);
}

.message__text a:focus,
.message__text .a:focus,
.message__text button:focus {
  background-color: #f5d9ab;
}

.message--red .message__text a:focus,
.message--red .message__text .a:focus,
.message--red .message__text button:focus,
.message--error .message__text a:focus,
.message--error .message__text .a:focus,
.message--error .message__text button:focus {
  background-color: #f9d2d5;
}

.message--green .message__text a:focus,
.message--green .message__text .a:focus,
.message--green .message__text button:focus,
.message--success .message__text a:focus,
.message--success .message__text .a:focus,
.message--success .message__text button:focus {
  background-color: #d5f6e3;
}

.message__cta {
  margin-left: 10px;
}
[dir="rtl"] .message__cta {
  margin-left: 0;
  margin-right: 10px;
}

@media screen and (max-width: 1024px) {
  .body--generic-filters-open .message--fixed,
  [dir="rtl"] .body--generic-filters-open .message--fixed {
    padding-left: 0;
    padding-right: 0;
  }
}

.tabs {
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
}

.tabs__list {
  display: flex;
  background: var(--primary-one);
}

.tabs__select-holder {
  display: none;
}
.tabs__button {
  position: relative;
  font-size: 1.4rem;
  line-height: 2.5rem;
  font-family: var(--maersk);
  color: #ffffff;
  padding: 19px 25px 20px;
  text-align: center;
  white-space: nowrap;
}
.tabs__button::after {
  content: "";
  display: block;
  height: calc(100% - 20px);
  width: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--neutral-grey);
}
.tabs__button--active {
  background: #ffffff;
  color: var(--dark-grey);
  box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
}
.tabs__button--active::after {
  display: none;
}

.tabs__panel {
  position: relative;
  padding: 45px 32px 32px;
  background: #ffffff;
}

.tabs--grid {
  background-color: transparent;
  box-shadow: none;
}
.tabs--grid .tabs__list {
  background-color: transparent;
  margin: 0 40px;
}
.tabs--grid .tabs__panel {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}
.tabs--grid .tabs__button {
  display: flex;
  align-items: center;
  margin-left: 16px;
  width: calc(33.33% - 16px);
  padding: 16px;
  font-size: 1.8rem;
  color: var(--primary-one);
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
  white-space: normal;
}
.tabs--grid .tabs__button::after {
  display: none;
}
[dir="rtl"] .tabs--grid .tabs__button {
  margin-left: 0;
  margin-right: 16px;
}
.tabs--grid .tabs__button:first-child,
[dir="rtl"] .tabs--grid .tabs__button:first-child {
  margin: 0;
}
.tabs--grid .tabs__button--active {
  border: 1px solid var(--primary-two);
}
.tabs--grid .tabs__button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  min-width: 56px;
  border-radius: 28px;
  margin-right: 8px;
  background-color: var(--primary-two-hint);
  border: 1px solid var(--primary-two);
}
[dir="rtl"] .tabs--grid .tabs__button-icon {
  margin-right: 0;
  margin-left: 8px;
}
.tabs--grid .tabs__button--active .tabs__button-icon {
  background-color: var(--primary-two);
}
.tabs--grid .tabs__button-icon path {
  fill: var(--primary-two);
}
.tabs--grid .tabs__button--active .tabs__button-icon path {
  fill: #ffffff;
}

.tabs--grey,
.tabs--grey .tabs__button--active,
.tabs--grey .tabs__panel {
  background-color: var(--white-three);
  box-shadow: none;
}

.tabs--stroke {
  box-shadow: none;
}
.tabs--stroke .tabs__list {
  background: none;
  border-bottom: 3px solid var(--white);
}
.tabs--stroke .tabs__select-holder {
  padding: 0;
}
.tabs--stroke .tabs__panel {
  padding: 45px 0 32px;
}
.tabs--stroke .tabs__button {
  color: var(--light-grey);
  font-size: 1.6rem;
}
.tabs--stroke .tabs__button::after {
  display: none;
}
.tabs--stroke .tabs__button--active {
  background: none;
  box-shadow: none;
  color: var(--primary-two);
}
.tabs--stroke .tabs__button--active::after {
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  position: absolute;
  top: calc(100% + 3px);
  background-color: var(--primary-two);
}

.tabs--select .tabs__select-holder {
  display: block;
  width: 100%;
  padding: 16px;
}
.tabs--select .tabs__select {
  width: auto;
  max-width: 100%;
  min-width: 280px;
}
.tabs--select .tabs__button {
  display: none;
}

@media screen and (max-width: 768px) {
  .tabs__select-holder {
    display: block;
    width: 100%;
    padding: 16px;
  }
  .tabs--select .tabs__select {
    width: 100%;
    min-width: 100%;
  }
  .tabs--grid .tabs__select-holder {
    padding: 0;
  }
  .tabs__button,
  .tabs--grid .tabs__button {
    display: none;
  }
  .tabs--stroke .tabs__list {
    border-bottom: 0;
  }
}

.drawers {
  margin-bottom: 40px;
  background-color: #ffffff;
}

.drawers__list {
  margin-top: 32px;
  padding: 0;
}

.drawers__button {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: 21px 70px 16px 24px;
  border-bottom: 2px solid var(--mid-grey-muted);
  color: var(--primary-one);
  font-size: 1.6rem;
  line-height: 2.7rem;
  font-family: var(--maersk-bold);
  transition: opacity var(--transition-mode) 0.4s;
  will-change: opacity;
  background-repeat: no-repeat;
  background-position: center right 20px;
  background-image: var(--arrow-down);
}
[dir="rtl"] .drawers__button {
  text-align: right;
  padding: 21px 24px 16px 70px;
  background-position: center left 20px;
}
.drawers__button[aria-expanded="true"] {
  border: 0;
  box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
  background-image: var(--arrow-up);
}

.drawers__definition {
  background-color: #f7f7f7;
  padding: 25px 40px 25px 24px;
}
.drawers__definition[aria-hidden="true"] {
  display: none;
}
.drawers__definition .button {
  display: block;
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  .drawers__definition .button {
    margin-top: 15px;
  }
}

.background-texture {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.06;
  transition: opacity 1s 2s;
}
.background-texture--fade {
  opacity: 0;
}
.background-texture__layer {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.background-texture__layer.background-texture__layer--overlay {
  top: 0;
  height: 20%;
  background: #ffffff;
  z-index: 1;
}
.background-texture__layer.background-texture__later--overlay-bottom {
  top: 120%;
}
.background-texture__layer.background-texture__layer--mask {
  top: 20%;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .background-texture {
    display: none;
  }
}

.quote-snippet {
  margin: 40px 16px 40px 12px;
}

.rich-text .quote-snippet__quote {
  position: relative;
  font-size: 2.8rem;
  line-height: 3.9rem;
  color: var(--primary-one);
  font-family: var(--maersk-light);
  margin: 0;
  padding: 0 12px 0 68px;
}

[dir="rtl"] .rich-text .quote-snippet__quote {
  padding: 0 68px 0 12px;
}

.quote-snippet__quote::before,
.quote-snippet__quote::after {
  content: "";
  position: absolute;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='44' height='59' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M15.385 37.607v5.47C6.888 43.077 0 36.954 0 29.402v-10.94h6.154v10.94c0 4.531 4.133 8.205 9.23 8.205zM6.154 6.154v12.308h12.308V6.154H6.154zM0 0h24.615v24.615H0V0z' id='a'/%3E%3Cpath d='M15.385 46.496v5.812C6.888 52.308 0 45.802 0 37.778V26.154h6.154v11.624c0 4.815 4.133 8.718 9.23 8.718zM6.154 6.154v18.461h18.461V6.154H6.154zM0 0h30.77v30.77H0V0z' id='b'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cuse fill='%239CC6CD' fill-rule='nonzero' xlink:href='%23a' opacity='.3' transform='translate(18.923 15.692)'/%3E%3Cuse fill='%230A6E80' fill-rule='nonzero' xlink:href='%23b' transform='translate(.462 .308)'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}
.quote-snippet__quote::before {
  width: 43px;
  height: 58px;
  background-size: 43px;
  left: 0;
  top: 0;
}
.quote-snippet__quote::after {
  width: 32px;
  height: 43.8px;
  background-size: 32px;
  bottom: 0;
  right: 0;
  transform: scaleX(-1);
}

[dir="rtl"] .quote-snippet__quote::before {
  left: auto;
  right: 0;
  transform: scaleX(-1);
}
[dir="rtl"] .quote-snippet__quote::after {
  right: auto;
  left: 0;
  transform: scaleX(1);
}

.quote-snippet__footer {
  position: relative;
  display: inline-block;
  padding: 0 100px 0 15px;
  text-align: left;
  font-family: var(--maersk-bold);
  font-size: 1rem;
  line-height: 0.83rem;
  margin-left: 68px;
  margin-top: 31px;
  text-transform: uppercase;
  color: var(--primary-one);
}
[dir="rtl"] .quote-snippet__footer {
  padding: 0 15px 0 100px;
  margin-left: 0;
  margin-right: 68px;
}

.quote-snippet__footer::after {
  position: absolute;
  content: "";
  display: block;
  width: 8px;
  height: 2px;
  top: 50%;
  margin-top: -1px;
  left: 0;
  right: auto;
  background: #737373;
  z-index: 0;
}
[dir="rtl"] .quote-snippet__footer::after {
  right: 0;
  left: auto;
}

@media screen and (min-width: 1920px) {
  .quote-snippet {
    max-width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .quote-snippet {
    margin: 40px 0 60px;
    padding: 0 27px;
    text-align: center;
    justify-content: center;
  }
  .rich-text .quote-snippet__quote,
  [dir="rtl"] .rich-text .quote-snippet__quote {
    font-size: 2.6rem;
    line-height: 3.4rem;
    padding: 0 10px;
    margin: 0;
  }
  .quote-snippet__quote::before,
  .quote-snippet__quote::after {
    width: 52px;
    height: 52px;
  }
  .quote-snippet__quote::before {
    background-size: 32px;
    top: 8px;
    left: -39px;
  }
  [dir="rtl"] .quote-snippet__quote::before {
    left: auto;
    right: -39px;
  }
  .quote-snippet__quote::after {
    bottom: -88px;
    right: -39px;
  }
  [dir="rtl"] .quote-snippet__quote::after {
    right: auto;
    left: -39px;
  }

  .quote-snippet__footer,
  [dir="rtl"] .quote-snippet__footer {
    margin: 16px 0 0;
    padding: 0 15px;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 0;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.pagination--hidden {
  opacity: 0;
}
.pagination__inner {
  display: flex;
  align-items: center;
  margin: 0 8px;
}
input[type="number"].pagination__input {
  width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2.5rem;
}

.fixed-table__container {
  position: relative;
}
.fixed-table[data-sticky-row="true"] .fixed-table__data-holder {
  position: relative;
}
.fixed-table__holder {
  transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 1.4rem;
  z-index: 2;
  background: #ffffff;
}
.fixed-table__holder--row {
  overflow: hidden;
  width: 100%;
  background: var(--primary-one);
}
.fixed-table__data {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-top: 1px solid #dee3e9;
  border-left: 1px solid #dee3e9;
  background: #ffffff;
}
.fixed-table__holder--row .fixed-table__data {
  position: absolute;
  background: var(--primary-one);
  color: #ffffff;
  font-family: var(--maersk);
  border: 0;
  height: 100%;
  line-height: 2rem;
  justify-content: flex-start;
}
.fixed-table__data:nth-child(odd) {
  background: #f7f7f7;
}
.fixed-table__data:first-child {
  background: var(--primary-one);
  color: #ffffff;
  position: relative;
  font-family: var(--maersk);
  border-top: 0;
}
.fixed-table__holder--row .fixed-table__data:first-child {
  position: absolute;
}
.fixed-table__data:first-child::after,
.fixed-table__holder--row .fixed-table__data::after {
  content: "";
  display: block;
  height: calc(100% - 20px);
  width: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--neutral-grey);
  z-index: 1;
}
[dir="rtl"] .fixed-table__data:first-child::after,
[dir="rtl"] .fixed-table__holder--row .fixed-table__data::after {
  right: auto;
  left: 0;
}
.fixed-table__data--border-hidden::after,
.fixed-table__data--border-hidden:first-child::after,
.fixed-table__holder--row .fixed-table__data--border::after,
.fixed-table__holder--row .fixed-table__data--border::before,
.fixed-table__holder--row
  .fixed-table__data.fixed-table__data--hide-divider::after {
  display: none;
}
.fixed-table__data--tooltip {
  background-color: var(--soft-yellow-hint);
}
.fixed-table__data--highlight,
.fixed-table__data--highlight:nth-child(odd) {
  background: #ebeae9;
}
.fixed-table__holder--row .fixed-table__data:nth-child(odd) {
  background: var(--primary-one);
}

[dir="rtl"] .fixed-table__holder {
  left: auto;
  right: 0;
}
[dir="rtl"] .fixed-table__data:first-child::after {
  right: auto;
  left: 0;
}

@media print {
  .fixed-table__container {
    display: block !important;
  }
  .fixed-table__holder {
    display: none;
  }
}

.collapsible-table__toggle {
  padding-left: 21px;
  background-image: var(--arrow-up);
  background-repeat: no-repeat;
  background-position: left center;
}
[dir="rtl"] .collapsible-table__toggle {
  padding-left: 0;
  padding-right: 21px;
}
.collapsible-table__toggle[aria-expanded="false"] {
  background-image: var(--arrow-down);
}

.collapsible-table--collapsed tbody {
  display: none;
}

.faq-cards {
  margin: 30px 0;
}

.tooltip {
  position: relative;
}
.tooltip__container {
  position: absolute;
  text-align: center;
  padding: 5px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 1rem;
  line-height: 1.4rem;
  transform: translateX(-50%);
  width: 125px;
  background: var(--primary-three);
  color: #ffffff;
}
.tooltip__container::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent var(--primary-three) transparent;
}
[dir="rtl"] .tooltip__container {
  transform: translateX(50%);
}

.tooltip__container[aria-hidden="true"] {
  display: none;
  opacity: 0;
}
.tooltip__container[aria-hidden="false"] {
  display: inline-block;
  opacity: 1;
}

.tooltip--extra .tooltip__container {
  width: 360px;
  padding: 30px;
  background: #ffffff;
  transform: translate(calc(-100% - 15px), -50%);
  box-shadow: var(--shadow-tilted);
  font-size: 1.4rem;
  line-height: 2.3rem;
  text-align: left;
  color: #2f2f2f;
}
.tooltip--extra .tooltip__container::after {
  bottom: auto;
  top: 50%;
  left: auto;
  right: -20px;
  transform: translateY(-50%) rotate(90deg);
  border-color: transparent transparent #ffffff;
}
[dir="rtl"] .tooltip--extra .tooltip__container {
  transform: translate(calc(100% + 15px), -50%);
}
[dir="rtl"] .tooltip--extra .tooltip__container::after {
  right: auto;
  left: -20px;
  transform: translateY(-50%) rotate(-90deg);
}

.scroll-overlay {
  opacity: 1;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 65, 101, 0.7);
  z-index: 2;
  color: #ffffff;
  transition: opacity 0.5s, z-index 0s 0.5s;
}
.scroll-overlay__ico {
  margin-bottom: 15px;
}
.scroll-overlay__front {
  animation-duration: 2s;
  animation-name: scrollHandFront;
  animation-iteration-count: 2;
  width: 30px;
  fill: #ffffff;
}
.scroll-overlay__back {
  animation-duration: 2s;
  animation-name: scrollHandBack;
  animation-iteration-count: 2;
  width: 40px;
  transform: translateX(-10px);
  fill: #ffffff;
}
.scroll-overlay__hand {
  animation-duration: 2s;
  animation-name: scrollHand;
  animation-iteration-count: 2;
  fill: #ffffff;
  transform-origin: 50% 50%;
  transform: rotate(-332deg) translate(48px, 5px);
  transition: transform 0.3s ease-in;
}

.scroll-overlay--right .scroll-overlay__ico {
  transform: scale(-1, 1);
}

[dir="rtl"] .scroll-overlay__ico {
  overflow: visible;
}
[dir="rtl"] .scroll-overlay__front {
  animation-name: scrollHandFrontRtl;
}
[dir="rtl"] .scroll-overlay__back {
  animation-name: scrollHandBackRtl;
}
[dir="rtl"] .scroll-overlay__hand {
  animation-name: scrollHandRtl;
}

@media screen and (max-width: 1024px) {
  .scroll-overlay {
    display: flex;
    justify-content: unset;
    padding-top: 40px;
  }
  .scroll-overlay--hidden {
    opacity: 0;
    z-index: -1;
  }
  .scroll-overlay--truck-turn-time-24-hour {
    justify-content: center;
    padding: 0;
  }
}

.dashboard-login {
  margin: 100px auto 120px;
  display: flex;
  background: #ffffff;
}
.dashboard-login__left-column {
  display: flex;
  flex-direction: column;
  margin: 0 var(--unit-one) 0 var(--unit-zero-half);
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .dashboard-login {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .dashboard-login__left-column {
    margin: auto;
    max-width: none;
    width: 100%;
  }
}

.toggle {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}
.toggle__indicator {
  position: relative;
  margin: 0 8px;
  width: 32px;
  height: 16px;
  border-radius: 10px;
  border: 1px solid;
}
.toggle__indicator::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 14px;
  width: 14px;
  border-radius: 9px;
  background: var(--white-two);
  transition: transform 0.05s;
}
.toggle--on .toggle__indicator {
  background-color: var(--green);
  border-color: var(--green);
}
.toggle--on .toggle__indicator::before {
  transform: translateX(18px);
}
.toggle__input {
  display: none;
}

.scroller {
  display: flex;
}
.scroller__prev,
.scroller__next {
  display: flex;
  width: 32px;
  height: 32px;
  border: 1px solid #dee3e9;
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
}
.scroller__prev {
  background-image: var(--arrow-left);
}
[dir="rtl"] .scroller__prev {
  background-image: var(--arrow-right);
}
.scroller__next {
  background-image: var(--arrow-right);
}
[dir="rtl"] .scroller__next {
  background-image: var(--arrow-left);
}
.scroller__prev:focus,
.scroller__next:focus {
  border-color: var(--primary-two);
}

.scroller__prev[disabled],
.scroller__next[disabled] {
  background-color: #bec4d0;
}
.scroller__prev[disabled] {
  background-image: var(--arrow-left-white);
}
[dir="rtl"] .scroller__prev[disabled] {
  background-image: var(--arrow-right-white);
}
.scroller__next[disabled] {
  background-image: var(--arrow-right-white);
}
[dir="rtl"] .scroller__next[disabled] {
  background-image: var(--arrow-left-white);
}

.journey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #ffffff;
  box-shadow: var(--shadow-tilted);
  border-radius: 24px;
  overflow: hidden;
}
.journey::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0;
  border-top: 1px dashed var(--light-grey);
}

.journey__step {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 8px 16px;
}
.journey__step:first-child {
  padding-left: 8px;
}
[dir="rtl"] .journey__step:first-child {
  padding-left: 16px;
  padding-right: 8px;
}

.journey__icon {
  height: 32px;
  width: 32px;
  min-width: 32px;
  margin-right: 8px;
}
[dir="rtl"] .journey__icon {
  margin-right: 0;
  margin-left: 8px;
}

@media screen and (max-width: 1024px) {
  .journey {
    flex-direction: column;
    align-items: flex-start;
    padding: 18px 0 24px;
  }
  .journey::before {
    border-top: 0;
    border-left: 1px dashed var(--light-grey);
    width: 0;
    height: calc(100% - 88px);
    top: 40px;
    left: 40px;
  }
  [dir="rtl"] .journey::before {
    left: auto;
    right: 40px;
  }

  .journey__step,
  .journey__step:first-child,
  [dir="rtl"] .journey__step,
  [dir="rtl"] .journey__step:first-child {
    padding: 8px 24px;
    background-color: transparent;
  }
}

.legend {
  display: flex;
  color: var(--dark-grey) !important;
  font-family: var(--maersk-bold);
  font-size: 1rem;
  line-height: 2.4rem;
  text-transform: uppercase;
}
.legend__item {
  margin: 0 0 0 10px;
  color: #ffffff;
}
[dir="rtl"] .legend__item {
  margin: 0 10px 0 0;
}
.legend__item--estimated {
  background-color: var(--maersk-blue);
}
.legend__item--latest {
  background-color: var(--mid-grey);
}
.legend__item--actual {
  background-color: var(--primary-two);
}
.legend__item path {
  fill: #ffffff;
}
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  .wrapper {
    width: 100%;
    padding: 0;
  }
  #main {
    display: block;
    padding-top: 0 !important;
  }

  .body--print-focus *:not(.print-focus) {
    display: none;
  }

  /* For when a modal is large enough to print on more than one page */
  .body--modal-print-focus {
    position: relative !important;
    overflow-y: auto !important;
    height: 100% !important;
    top: 0 !important;
  }

  .trace-listing__table-container {
    overflow: visible;
  }

  .trace-print *,
  .trace-listing__filters {
    display: none;
  }

  .trace-listing__el--hidden {
    display: none !important;
  }

  .trace-listing,
  .trace-container,
  .trace-listing .wrapper,
  .trace-listing__table-container,
  .trace-listing__result-count,
  .trace-listing__operators-list,
  .trace-listing__bill-list,
  .trace-listing__bill,
  .track-listing__date {
    display: block;
  }
  .trace-listing__icon {
    display: inline-block;
  }
  .trace-listing__results,
  .trace-listing__operator,
  .trace-listing__cell.tooltip > span {
    display: inline;
  }

  .trace-listing__table {
    display: table;
  }
  .trace-listing__thead,
  .trace-listing__tbody {
    display: table-header-group;
  }
  .trace-listing__cell {
    display: table-cell;
  }
  .trace-listing__header {
    display: table-cell;
    height: auto;
  }
  .trace-listing__row {
    display: table-row;
  }
}
