.navigation {
  &__link {
    position: relative;
    width: 100%;
    text-align: left;
    font-family: var(--maersk);
    color: #2f2f2f;
    transition: color var(--transition-mode) var(--transition-speed);
    cursor: pointer;

    &[aria-haspopup="true"]::after {
      content: "";
      position: absolute;
      display: block;
      left: -50%;
      margin: auto;
      right: -50%;
      bottom: 0;
      width: 0;
      height: 2px;
      background: var(--primary-two);
      transition: width var(--transition-mode) 0.3s;
    }

    &--active,
    &:hover {
      color: var(--primary-two);
      margin: 0;
      padding: 0;
      background-color: transparent;
    }
  }
}

.navigation--terminal .navigation__link--active[aria-haspopup="true"]::after,
.navigation--global .navigation__link--active[aria-haspopup="true"]::after {
  width: calc(100% + 38px);
}

.secondary-navigation {
  position: absolute;
  z-index: -3;
  width: 100%;
  background: #ffffff;
  display: none;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-50px);
  top: 100%;
  left: 0;
  padding: 0 52px;
  transition-property: transform, opacity;
  transition-timing-function: var(--transition-mode);
  transition-duration: 0.4s;
  will-change: transform, opacity;

  &--active {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
    z-index: -2;
  }

  &--with-border {
    box-shadow: 0 15px 15px -15px rgba(197, 197, 197, 0.5);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 30px;
    padding: 52px 0 22px;
    transition: none;
    flex-direction: row;
  }

  &__item {
    width: 25%;
    padding-right: 40px;
    margin-bottom: 30px;
    opacity: 0;
    transform: translateX(80px);
    transition: all ease-out 0.4s;
    white-space: normal;
    font-size: 15px;
  }

  &__link {
    pointer-events: none;
    position: relative;
    display: block;
    font-family: var(--maersk);
    color: #2f2f2f;
    transition: all var(--transition-mode) var(--transition-speed);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      padding: 0 0 0 20px;
      margin: 0 -20px 0 0;
      color: var(--primary-two);
      text-decoration: none;
      background-color: transparent;

      &::before {
        width: 10px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 11px;
      width: 0;
      height: 2px;
      background: var(--primary-two);
      transition: width var(--transition-mode) var(--transition-speed);
    }
  }
}

.secondary-navigation__item--visible,
[dir="rtl"] .secondary-navigation__item--visible {
  opacity: 1;
  transform: translateX(0);
}

.secondary-navigation--active .secondary-navigation__link {
  pointer-events: auto;
}

.crisis-alert__text-container {
  flex: 1;
  margin: 0;
  padding: 40px 80px;
  text-align: center;
}

.global-alert--container {
  width: 500px;
}

@media screen and (max-width: 1024px) {
  .global-alert--container {
    width: calc(100% - var(--wrapper-margin-narrow) * 2);
  }
}
