.header {
  box-sizing: content-box;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #ffffff;
  font-family: "MaerskText-Regular", helvetica, sans-serif;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;

  &__main-bar {
    display: flex;
    align-items: center;
    height: 90px;
    background: #ffffff;
    box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
    .links{
      display:flex;
      flex-direction:row;
      gap:38px;
      padding-right:38px;
      @media screen and (max-width: 600px) {
        display: none;
      }
      .skeleton-box {
        width:100px;
        display: inline-block;
        height: 8px;
        position: relative;
        overflow: hidden;
        background-color: #DDDBDD;
        &:nth-child(7){
          display: none;
        }
        &:last-child{
          width: 200px;
          margin-left: 40px;
        }
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
          background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
          );
          animation: shimmer 1s infinite;
          content: '';
        }
      
        @keyframes shimmer {
          100% {
            transform: translateX(100%);
          }
        }
      }
    }
    
    @media screen and (max-width: 1024px) {
      height: 60px;
      flex-wrap: wrap;
      padding: 0 20px;
      background: #ffffff;
      border: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
      box-shadow: none;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
        box-shadow: 0 2px 15px 0 rgba(197, 197, 197, 0.5);
      }
      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        width: 100%;
        background: #ffffff;
        top: 60px;
        height: calc(100vh - 60px);
        transform: translateY(-100%);
        opacity: 0;
        transition-property: transform, opacity;
        transition-timing-function: var(--transition-mode);
        transition-duration: var(--transition-speed);
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    margin: 0 auto 0 30px !important;
    padding: 0 !important;
    background-color: transparent !important;
    width: 200px;
    @media screen and (max-width: 1024px) {
      width: 175px;
      margin: 0 auto 0 0 !important;
      position: relative;
    }
    svg {
      width: 100%;
      height: auto;
      display: block;
    }

    &-tagline {
      display: inline;
      width: 100%;
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
    &-no-tagline {
      display: none;
      width: 100%;
      @media screen and (max-width: 1024px) {
        display: inline;
      }
    }

    svg:not(:root) {
      overflow: hidden;
    }
  }

  .navigation,
  .navigation__list,
  .navigation__item,
  .navigation__link {
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: row;
    @media screen and (max-width: 1024px) {
      display: block;
      height: auto;
    }
  }

  .navigation {
    &__list {
      &--logged-out {
        display: flex;
      }
      &--logged-in {
        position: relative;
        display: flex;
        .login-header__icon {
          width: 30px;
          height: 30px;
          border-radius: 100%;
          border: 2px solid var(--primary-two);
          background: #ffe0d9;
          path {
            fill: var(--primary-two);
          }
        }
        .navigation__item {
          position: relative;
          .logout__container {
            display: none;
            background: #fff;
            box-shadow: 0 2px 30px 0 hsla(0, 0%, 77%, 0.8);
            overflow-y: visible;
            padding: 20px;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 56px;
            transform: translateY(40px);
            transition-duration: 0.3s;
            visibility: visible;
            width: 180px;
            z-index: 2;
            left: 0px;
            pointer-events: all;
            transition: all 0.5s ease;
            &.move-left {
              right: 11px;
              left: auto;
              &:before {
                right: 11px;
                left: auto;
              }
              @media screen and (max-width: 1280px) {
                right: -4px;
              }
            }
            @media screen and (max-width: 1024px) {
              right: 0px;
              left: auto;
            }
            &.animate_logout {
              opacity: 1;
              transform: translateY(20px);
            }
            &__list {
              display: block;
              padding: 0;
              margin: 0 auto;
              max-width: 1200px;
              &__item {
                opacity: 1;
                transform: none;
                transition: none;
                width: 100%;
                &:not(:last-child) {
                  margin-bottom: 15px;
                }
                a {
                  font-size: 15px;
                  color: #2f2f2f;
                  display: block;
                  font-family: var(--maersk);
                  position: relative;
                  text-decoration: none;
                  transition: all var(--transition-mode) var(--transition-speed);
                  &:focus {
                    background: none;
                  }
                  &:hover {
                    background: transparent;
                    color: var(--primary-two);
                    margin: 0 -20px 0 0;
                    padding: 0 0 0 20px;
                    text-decoration: none;
                    &::before {
                      width: 10px;
                    }
                  }
                  &::before {
                    background: var(--primary-two);
                    content: "";
                    height: 2px;
                    left: 0;
                    position: absolute;
                    top: 11px;
                    transition: width var(--transition-mode)
                      var(--transition-speed);
                    width: 0;
                  }
                }
              }
            }
            &::before {
              background: #fff;
              content: "";
              height: 12px;
              left: 10px;
              opacity: 1;
              position: absolute;
              top: -6px;
              transform: rotate(45deg);
              transition: opacity var(--transition-mode) 0.3s;
              width: 12px;
              @media screen and (max-width: 1024px) {
                right: 11px;
                left: auto;
              }
            }
          }
        }
      }
    }
    &__arrow-icon {
      @media screen and (max-width: 1024px) {
        position: absolute;
        right: 10px;
        top: 10px;
        transition: transform var(--transition-mode) var(--transition-speed);
        transform-origin: -7px 0;
        &::before,
        &::after {
          position: absolute;
          right: 5px;
          top: 0;
          content: "";
          width: 9px;
          height: 2px;
          transform: rotate(50deg);
          background: var(--dark-grey);
        }
        &::after {
          transform: rotate(-50deg);
          right: 0;
        }
      }
    }
    &__link {
      position: relative;
      width: 100%;
      text-align: left;
      font-family: var(--maersk);
      color: #2f2f2f;
      transition: color var(--transition-mode) var(--transition-speed);
      cursor: pointer;
      position: relative;
      width: 100%;
      text-align: left;
      font-family: var(--maersk);
      color: #2f2f2f;
      transition: color var(--transition-mode) var(--transition-speed);
      cursor: pointer;
      &--active {
        color: var(--primary-two);
        margin: 0;
        padding: 0;
        background-color: transparent;
        &[aria-haspopup="true"]::after {
          width: calc(100% + 38px);
        }
        @media screen and (max-width: 1024px) {
          .navigation__arrow-icon {
            transform: rotate(-180deg);
          }
        }
      }
      &:focus{
        background-color: transparent!important;
      }
      &[aria-haspopup="true"]::after {
        content: "";
        position: absolute;
        display: block;
        left: -50%;
        margin: auto;
        right: -50%;
        bottom: 0;
        width: 0;
        height: 2px;
        background: var(--primary-two);
        transition: width var(--transition-mode) 0.3s;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
      &:hover {
        color: var(--primary-two);
        margin: 0;
        padding: 0;
        background-color: transparent;
      }
    }
    &__item {
      margin-right: 38px;
      font-size: 1.6rem;
      line-height: 2.2rem;
      white-space: nowrap;
      .login-header {
        display: flex;
        align-items: center;
        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 17px;
          @media screen and (max-width: 1280px) {
            margin: 0 !important;
          }
        }
        &__profile {
          text-decoration: none;
          &:hover {
            text-decoration: none;
            cursor: pointer;
          }
        }
        &__text {
          @media screen and (max-width: 1280px) {
            display: none;
          }
        }
      }
      &:first-child {
        margin-left: 38px;
      }
      @media screen and (max-width: 1400px) {
        &:first-child {
          margin-left: 20px;
        }
        margin-right: 20px;
      }
      @media screen and (max-width: 1280px) {
        font-size: 1.4rem;
      }
      @media screen and (max-width: 1024px) {
        width: calc(100% - 28px);
        padding: 14px 0;
        margin: 0 14px !important;
        border-top: 2px solid var(--white-three);
        .secondary-navigation {
          display: none;
          visibility: visible;
          position: relative;
          z-index: 1;
          top: auto;
          padding: 0;
          opacity: 1;
          box-shadow: none;
          transform: none;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 14px;
            z-index: 2;
            display: block;
            height: 2px;
            width: 100%;
            background: var(--white-three);
            opacity: 1;
          }
          &__list {
            padding: 20px 30px;
            margin-top: 14px;
            opacity: 0;
            transform: translateY(-20px);
            transition: all var(--transition-mode) 0.4s;
            transition-duration: 0.3s;
            margin-left: 0;
            margin-right: 0;
            flex-direction: row;
          }
          &__item {
            width: 100%;
            margin-bottom: 15px;
            opacity: 1;
            transform: none;
            transition: none;
            padding: 0;
            font-size: 14px;
          }
        }
      }
    }
    &--global {
      @media screen and (max-width: 1024px) {
        position: absolute;
        width: calc(100% - 40px);
        left: 20px;
        top: 215px;
        max-height: calc(100vh - 215px - 50px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transition: all var(--transition-mode) var(--transition-speed);
      }
    }
  }

  &__group {
    display: flex;
    height: 100%;
    align-items: center;

    .terminal-selector__show-button {
      height: 22px;
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 18px 22px;
      color: #ff6441;
      padding-left: 32px;
      white-space: nowrap;
    }
    @media screen and (max-width: 1024px) {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 0;
      opacity: 0;
      display: none;
      transform: translateY(-50px);
      transition-property: transform, opacity;
      transition-timing-function: var(--transition-mode);
      transition-duration: 0.5s;
      .terminal-selector {
        display: none;
        position: absolute;
        width: calc(100% - 40px);
        margin: 0 20px;
        top: 85px;
        z-index: 3 !important;
        &__show-button {
          font-size: 1.4rem;
          width: 100%;
          height: 50px;
          padding: 0;
          margin: 0;
          background: var(--primary-two);
          color: #ffffff;
        }
      }
    }
    .search-bar {
      width: 20px;
      height: 100%;
      margin-right: 48px;
      position: relative;
      transition: transform var(--transition-mode) var(--transition-speed);
      @media screen and (max-width: 1400px) {
        margin-right: 20px;
      }
      @media screen and (max-width: 1024px) {
        position: absolute;
        height: 50px;
        top: 135px;
        width: calc(100% - 40px);
        margin: 11px 20px 0;
      }
      &__button {
        min-width: 90px;
        margin: 0 20px;
        cursor: pointer;
        background-color: #ffffff;
      }
      &__button-wrap {
        opacity: 0;
        display: flex;
        align-items: center;
        position: absolute;
        left: calc(100% + 50px);
        top: 0;
        height: 100%;
        transition: opacity var(--transition-mode) var(--transition-speed);
      }
      &--open {
        @media screen and (max-width: 1400px) {
          margin-right: 48px;
        }
        .search-bar__form {
          overflow: visible;
        }
        .search-bar__button {
          &:hover {
            background-color: var(--primary-two);
          }
          &:focus {
            margin: 0 20px;
          }
        }
        .search-bar__input {
          display: block;
          &::placeholder {
            color: var(--primary-one);
          }
        }
        .search-bar__hide {
          opacity: 1;
          &::after,
          &::before {
            width: 15px;
          }
        }
        .search-bar__button-wrap {
          opacity: 1;
        }
      }
      .close-button {
        display: block;
        width: 26px;
        height: 26px;
        &::after,
        &::before {
          content: "";
          position: absolute;
          height: 2px;
          background-color: var(--dark-grey);
          transform: rotate(45deg);
          top: calc(50% - 1px);
          right: 0;
          margin: auto;
          transition: background-color var(--transition-speed)
            var(--transition-mode);
        }
        &::after {
          transform: rotate(-45deg);
        }
        &:hover {
          &::after,
          &::before {
            background: var(--primary-two);
          }
        }
      }
      &__show {
        &:hover {
          path {
            fill: var(--primary-two);
          }
        }
        path {
          transition: fill var(--transition-mode) var(--transition-speed);
        }
        @media screen and (max-width: 1024px) {
          position: absolute;
          right: 0;
          left: auto;
          top: 0;
          height: 100%;
          width: 60px;
          display: block;
        }
      }
      &__icon,
      &__icon img {
        display: block;
        width: 20px;
        height: 20px;
        margin: auto;
      }
      &__hide {
        opacity: 0;
        position: absolute;
        right: -26px;
        transition: opacity var(--transition-mode) var(--transition-speed);
        top: 50%;
        transform: translateY(-50%);
        y &::after,
        &::before {
          width: 15px;
        }
      }
      &__input {
        display: none;
        flex: 1;
        border: 0;
        height: 100%;
        padding: 0 18px;
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-family: var(--maersk);
        background-color: transparent;
        @media screen and (max-width: 1024px) {
          display: block;
          padding: 0 60px 0 16px;
          opacity: 1;
          border: 1px solid var(--mid-grey-hint);
          background-color: var(--white-four);
        }
      }
      &__form {
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: #ffffff;
        display: flex;
        align-items: center;
        z-index: 1;
        transition: width var(--transition-mode) var(--transition-speed);
      }
    }
  }
  .menu-icon {
    display: none;
    position: relative;
    width: 26px;
    height: 26px;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    &__line {
      position: absolute;
      content: "";
      top: 3px;
      display: block;
      height: 2px;
      width: 100%;
      background: var(--dark-grey);
      transition: transform var(--transition-mode) var(--transition-speed);
      &:nth-child(2) {
        top: 12px;
        transition: opacity var(--transition-mode) 0.3s;
      }
      &:nth-child(3) {
        top: 21px;
      }
    }
  }
  &--navigation-open {
    @media screen and (max-width: 1024px) {
      .header {
        &__main-bar {
          &:after {
            transform: translateY(0);
            opacity: 1;
          }
          &.header__main-bar--terminal {
            position: static;
          }
        }
        &__group {
          display: block;
        }
      }
    }
    &.header {
      &--fade-in-navigation {
        @media screen and (max-width: 1024px) {
          .header {
            &__group {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }
    .menu-icon {
      &__line {
        &:first-child {
          transform: translate3d(0, 10px, 0) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translate3d(0, -8px, 0) rotate(-45deg);
        }
      }
    }
    .header__group .terminal-selector {
      display: block;
    }
  }

  &__separator {
    display: block;
    width: 2px;
    height: 30px;
    background-color: var(--pinkish-grey);
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
