.terminal-selector {
  &.header--large {
    height: 150px;
    @media screen and (max-width: 1024px) {
      height: 60px;
      .navigation {
        max-height: calc(100vh - 375px);
        min-height: 28px;
      }
    }
  }
  &.header--slim {
    height: 90px;

    &.header--alert {
      height: 130px;
    }
    &.header--alert-open {
      height: 195px;
    }
  }
  &.header--alert {
    height: 190px;
  }
  &.header--alert-open {
    height: 255px;
  }
  .header {
    &__middle-bar {
      position: relative;
      display: flex;
      align-items: center;
      height: 60px;
      border-bottom: 1px solid rgba(197, 197, 197, 0.5);
      background: var(--white-three);
      box-shadow: inset 0 -11px 8px -10px rgba(197, 197, 197, 0);
      transition: box-shadow var(--transition-mode) var(--transition-speed),
        transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
      .main__logo {
        width: 160px;
        display: flex;
        align-items: center;
        margin: 0 auto 0 30px;
        padding: 0;
        background-color: transparent;
        @media screen and (max-width: 1024px) {
          width: 175px;
          margin: 0 auto 0 0 !important;
          position: relative;
        }
        svg {
          width: 100%;
          height: auto;
          display: block;
        }
        &-no-tagline {
          width: 100%;
          @media screen and (max-width: 1024px) {
            display: inline;
          }
        }

        svg:not(:root) {
          overflow: hidden;
        }
      }
    }

    &__main-bar {
      display: flex;
      align-items: center;
      height: 90px;
      background: #ffffff;
      box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
    }

    &__main-bar--terminal {
      position: absolute;
      width: 100%;
      bottom: 0;
    }

    &__group {
      display: flex;
      height: 100%;
      align-items: center;
    }

    &--large,
    &__logo {
      width: 160px;

      &:focus {
        display: flex;
        align-items: center;
        margin: 0 auto 0 30px;
        padding: 0;
        background-color: transparent;
      }
    }

    &__logo {
      display: flex;
      align-items: center;
      margin: 0 auto 0 30px;
      padding: 0;
      background-color: transparent;
    }

    &__logo-link--terminal {
      transition: all var(--transition-mode) 0.3s;
    }

    &__logo-link {
      font-family: var(--maersk-bold);
      text-transform: uppercase;
      color: var(--primary-one);
      display: inline-flex;
      align-items: center;
      margin: 0 auto 0 0;
      padding: 0;
      background-color: transparent;
    }
  }

  .header__logo-link--terminal {
    transition: all var(--transition-mode) 0.5s;

    &:hover {
      color: var(--primary-two);
      text-decoration: underline !important;
    }
    &:focus {
      color: var(--primary-two);
      outline: 0;
      margin: 0 -8px -4px;
      padding: 0 8px 4px;
      background-color: var(--primary-two-hint);
    }
  }

  .header__logo-link {
    font-family: var(--maersk-bold);
    text-transform: uppercase;
    color: var(--primary-one);
    display: inline-flex;
    align-items: center;
    @media screen and(max-width:1024px) {
      width: 100%;
      justify-content: center;
    }
  }

  .header__logo-link--apm {
    display: none;
  }

  .navigation,
  .navigation__list,
  .navigation__item,
  .navigation__link {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .navigation__item {
    margin-right: 38px;
    font-size: 1.6rem;
    line-height: 2.2rem;
    white-space: nowrap;
    .logout__container{
      top: 40px !important;
    }
  }
  [lang="ar"] .navigation__item {
    font-size: 1.8rem;
  }
  
  .navigation__item:first-child {
    margin-left: 38px;
  }
  
  .navigation--dropdown .navigation__item {
    position: relative;
  }

  .navigation__link {
    position: relative;
    width: 100%;
    text-align: left;
    font-family: var(--maersk);
    color: #2f2f2f;
    transition: color var(--transition-mode) var(--transition-speed);
    cursor: pointer;
  }
  
  .navigation--terminal .navigation__link[aria-haspopup="true"]::after,
  .navigation--global .navigation__link[aria-haspopup="true"]::after {
    content: "";
    position: absolute;
    display: block;
    left: -50%;
    margin: auto;
    right: -50%;
    bottom: 0;
    width: 0;
    height: 2px;
    background: var(--primary-two);
    transition: width var(--transition-mode) 0.3s;
  }
  .navigation--terminal .navigation__link--active[aria-haspopup="true"]::after,
  .navigation--global .navigation__link--active[aria-haspopup="true"]::after {
    width: calc(100% + 38px);
  }
  .navigation__link--active,
  .navigation__link:hover,
  .navigation__link:focus {
    color: var(--primary-two);
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  .navigation__link-icon {
    width: auto;
    vertical-align: middle;
    margin-right: 10px;
  }

  .navigation__item--highlight {
    padding-left: 40px;
    padding-right: 40px;
    background: var(--primary-three-hint);
  }
  .navigation__item--highlight .navigation__link,
  .navigation__item--highlight .navigation__link:hover,
  .navigation__item--highlight .navigation__link:focus {
    color: var(--primary-three);
  }
  .navigation__item--highlight .navigation__link[aria-haspopup="true"]::after {
    background: var(--primary-three);
  }
  .navigation__item--highlight
    .navigation__link.navigation__link--active[aria-haspopup="true"]::after {
    background: var(--primary-three);
    width: calc(100% + 80px);
  }

  /* secondary navigation - base styles */
  .secondary-navigation {
    position: absolute;
    z-index: -3;
    width: 100%;
    background: #ffffff;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-50px);
    top: 100%;
    left: 0;
    padding: 0 52px;
    transition-property: transform, opacity;
    transition-timing-function: var(--transition-mode);
    transition-duration: 0.4s;
    will-change: transform, opacity;
  }
  .secondary-navigation::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--white-three);
    opacity: 0;
    transition: opacity var(--transition-mode) 0.3s;
  }
  .secondary-navigation--active {
    visibility: visible;
    display: block;
    transform: translateY(0);
    opacity: 1;
    z-index: -2;
  }
  .secondary-navigation--with-border {
    box-shadow: 0 15px 15px -15px rgba(197, 197, 197, 0.5);
  }
  .secondary-navigation--with-border::before {
    opacity: 1;
    transition: opacity var(--transition-mode) 0.3s;
  }

  .secondary-navigation__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 52px 0 22px;
    transition: none;
  }

  .secondary-navigation__item {
    width: 25%;
    padding-right: 40px;
    margin-bottom: 30px;
    opacity: 0;
    transform: translateX(80px);
    transition: all ease-out 0.4s;
    white-space: normal;
  }
  .secondary-navigation__item:nth-child(4n + 2){
    transition-delay: 0.05s;
  }
  .secondary-navigation__item:nth-child(4n + 3){
    transition-delay: 0.1s;
  }
  .secondary-navigation__item:nth-child(4n + 4){
    transition-delay: 0.15s;
  }
  .secondary-navigation__item--visible{
    opacity: 1;
    transform: translateX(0);
  }

  .secondary-navigation__link {
    pointer-events: none;
    position: relative;
    display: block;
    font-family: var(--maersk);
    color: #2f2f2f;
    transition: all var(--transition-mode) var(--transition-speed);
  }
  .secondary-navigation--active .secondary-navigation__link {
    pointer-events: auto;
  }
  .secondary-navigation__link:hover,
  .secondary-navigation__link:focus,
  .secondary-navigation__link:active {
    padding: 0 0 0 20px;
    margin: 0 -20px 0 0;
    color: var(--primary-two);
    text-decoration: none;
    background-color: transparent;
  }
  .secondary-navigation__link::before {
    content: "";
    position: absolute;
    left: 0;
    top: 11px;
    width: 0;
    height: 2px;
    background: var(--primary-two);
    transition: width var(--transition-mode) var(--transition-speed);
  }
  .secondary-navigation__link:hover::before,
  .secondary-navigation__link:focus::before {
    width: 10px;
  }

  /* secondary navigation of the global navigation - only present in the terminal pages, where there is a global level navigation and a terminal level navigation */
  .secondary-navigation--global {
    top: 60px;
    transform: none;
    overflow: hidden;
  }

  /* secondary navigation of the terminal navigation */
  .navigation--dropdown .secondary-navigation {
    pointer-events: none;
    padding: 20px;
    top: calc(100% + 30px);
    left: -18px;
    width: 225px;
    overflow-y: visible;
    transform: translateY(20px);
    box-shadow: 0 2px 30px 0 rgba(197, 197, 197, 0.8);
    transition-duration: 0.3s;
  }
  .navigation--dropdown .secondary-navigation--reverse {
    left: auto;
    right: -18px;
  }
  .navigation--dropdown .secondary-navigation--active {
    pointer-events: all;
    transform: translateY(0);
    opacity: 1;
  }
  .navigation--dropdown .secondary-navigation::before {
    top: -6px;
    left: 31px;
    width: 12px;
    height: 12px;
    opacity: 1;
    background: #ffffff;
    transform: rotateZ(45deg);
  }
  .navigation--dropdown .secondary-navigation--reverse::before {
    left: auto;
    right: 31px;
  }
  .navigation--dropdown .secondary-navigation__list {
    display: block;
    padding: 0;
  }
  .secondary-navigation__link--active {
    pointer-events: all;
  }
  .navigation--dropdown .secondary-navigation__item {
    width: 100%;
    margin-bottom: 15px;
    opacity: 1;
    transition: none;
    transform: none;
  }
  .navigation--dropdown .secondary-navigation__item:last-child {
    margin-bottom: 0;
  }

  /* only shown on terminal header - shows message to tell the user to swipe to switch from global to terminal navigation */
  .navigation__text-helper {
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
      position: fixed;
      z-index: 2;
      top: calc(100vh - 56px - 50px);
      background: #ffffff;
      padding: 17px 0;
      width: calc(100% - 40px);
      left: 20px;
      color: var(--primary-two);
      text-align: center;
      font-size: 1.4rem;
      line-height: 2rem;
      border-top: 2px solid var(--white-three);
      transition-duration: 0.5s;
      .navigation__icon-helper {
        margin: 0 5px;
        position: relative;
        top: 2px;
      }
    }
  }

  /* toggleable global nav on terminal header */
  .toggle--link {
    &.navigation--global {
      .navigation__list {
        position: absolute;
        z-index: -1;
        left: 0;
        width: 100%;
        top: 100%;
        height: 60px;
        justify-content: flex-end;
        align-items: center;
        background: #ffffff;
        transform: translateY(-100%);
        transition: transform var(--transition-mode) var(--transition-speed);
      }
      @media screen and (max-width: 1024px) {
        opacity: 0;
        transform: translateX(110%);
        top: 263px;
        max-height: calc(100vh - 375px);
        .navigation__list {
          position: static;
          height: auto;
          transform: translateY(0);
          .navigation__link--active {
            &::after {
              display: none;
            }
          }
          .secondary-navigation {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  .navigation--global .navigation__toggle--active + .navigation__list {
    transform: translateY(0);
  }

  .navigation__toggle {
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    margin: 0 -2px 0 20px;
    padding: 0 20px;
    transition: all var(--transition-mode) var(--transition-speed);
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .navigation__toggle--active {
    height: 61px;
    margin-bottom: -1px;
    background: #ffffff;
  }

  .navigation__toggle-icon {
    position: relative;
    height: 14px;
    width: 14px;
    display: block;
    margin-right: 10px;
  }
  .navigation__toggle-dot {
    position: absolute;
    width: 2px;
    height: 2px;
    display: block;
    background: #776f65;
  }
  .navigation__toggle-dot:nth-child(1) {
    left: 0;
    top: 0;
  }
  .navigation__toggle-dot:nth-child(2) {
    left: calc(50% - 1px);
    top: 0;
  }
  .navigation__toggle-dot:nth-child(3) {
    left: calc(100% - 2px);
    top: 0;
  }
  .navigation__toggle-dot:nth-child(4) {
    left: 0;
    top: calc(50% - 1px);
  }
  .navigation__toggle-dot:nth-child(5) {
    left: calc(50% - 1px);
    top: calc(50% - 1px);
  }
  .navigation__toggle-dot:nth-child(6) {
    left: calc(100% - 2px);
    top: calc(50% - 1px);
  }
  .navigation__toggle-dot:nth-child(7) {
    left: 0;
    top: calc(100% - 2px);
  }
  .navigation__toggle-dot:nth-child(8) {
    left: calc(50% - 1px);
    top: calc(100% - 2px);
  }
  .navigation__toggle-dot:nth-child(9) {
    left: calc(100% - 2px);
    top: calc(100% - 2px);
  }

  .navigation__toggle-dot:nth-child(2),
  .navigation__toggle-dot:nth-child(4),
  .navigation__toggle-dot:nth-child(5),
  .navigation__toggle-dot:nth-child(6),
  .navigation__toggle-dot:nth-child(8) {
    transition: opacity var(--transition-mode) 100ms 0s;
  }
  .navigation__toggle--active .navigation__toggle-dot:nth-child(2),
  .navigation__toggle--active .navigation__toggle-dot:nth-child(4),
  .navigation__toggle--active .navigation__toggle-dot:nth-child(5),
  .navigation__toggle--active .navigation__toggle-dot:nth-child(6),
  .navigation__toggle--active .navigation__toggle-dot:nth-child(8) {
    opacity: 0;
  }

  .navigation__toggle-dot:nth-child(1),
  .navigation__toggle-dot:nth-child(3),
  .navigation__toggle-dot:nth-child(7),
  .navigation__toggle-dot:nth-child(9) {
    transition: transform var(--transition-mode) 200ms 0s,
      height var(--transition-mode) 200ms 0s;
  }
  .navigation__toggle-dot:nth-child(1) {
    transform-origin: 1px 1px;
  }
  .navigation__toggle-dot:nth-child(3) {
    transform-origin: calc(100% - 1px) 1px;
  }
  .navigation__toggle-dot:nth-child(7) {
    transform-origin: 1px 1px;
  }
  .navigation__toggle-dot:nth-child(9) {
    transform-origin: calc(100% - 1px) 1px;
  }
  .navigation__toggle--active .navigation__toggle-dot:nth-child(1) {
    transform: rotate(-45deg);
    height: 10px;
  }
  .navigation__toggle--active .navigation__toggle-dot:nth-child(3) {
    transform: rotate(45deg);
    height: 10px;
  }
  .navigation__toggle--active .navigation__toggle-dot:nth-child(7) {
    transform: rotate(45deg) translateY(-8px);
    height: 10px;
  }
  .navigation__toggle--active .navigation__toggle-dot:nth-child(9) {
    transform: rotate(-45deg) translateY(-8px);
    height: 10px;
  }
  .navigation--login .close-button,
  .navigation--language .close-button {
    display: none;
  }

  /* tertiary navigation */
  .tertiary-navigation {
    position: absolute;
    z-index: -3;
    background: #ffffff;
    visibility: hidden;
    opacity: 0;
    transition-property: transform, opacity;
    transition-timing-function: var(--transition-mode);
    will-change: transform, opacity;
    padding: 20px;
    top: 40px;
    left: -18px;
    width: 225px;
    overflow-y: visible;
    transform: translateY(20px);
    box-shadow: 0 2px 30px 0 rgba(197, 197, 197, 0.8);
    transition-duration: 0.3s;
  }
  .tertiary-navigation::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 31px;
    width: 12px;
    height: 12px;
    background: #ffffff;
    opacity: 1;
    transition: opacity var(--transition-mode) 0.3s;
    transform: rotateZ(45deg);
  }
  .tertiary-navigation--active {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
    z-index: -2;
  }
  .tertiary-navigation__item {
    margin-bottom: 15px;
  }
  .tertiary-navigation__link {
    pointer-events: none;
    position: relative;
    display: inline-block;
    color: #2f2f2f;
    transition: all var(--transition-mode) var(--transition-speed);
    font-family: var(--maersk);
  }
  .tertiary-navigation--active .tertiary-navigation__link {
    pointer-events: auto;
  }
  .tertiary-navigation__link:hover,
  .tertiary-navigation__link:focus,
  .tertiary-navigation__link:active {
    transform: translateX(20px);
    color: var(--primary-two);
    text-decoration: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
  .tertiary-navigation__link::before {
    content: "";
    position: absolute;
    right: calc(100% + 10px);
    top: 11px;
    width: 0;
    height: 2px;
    background: var(--primary-two);
    transition: width var(--transition-mode) var(--transition-speed);
  }
  .tertiary-navigation__link:hover::before,
  .tertiary-navigation__link:focus::before {
    width: 10px;
  }

  @media screen and (max-width: 1400px) {
    .navigation__item {
      margin-right: 20px;
    }
    .navigation__item:first-child {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1280px) {
    .navigation__item {
      font-size: 1.4rem;
    }
    .navigation--login .navigation__item,
    .navigation--language .navigation__item {
      margin: 0 20px;
    }
  }

  @media screen and (max-width: 1024px) {
    .navigation,
    .navigation__list,
    .navigation__item,
    .navigation__link {
      display: block;
      height: auto;
    }

    &__logo-link--apm {
      display: none;
    }
  }

  .language-selector {
    display: flex;
    align-items: center;
  }
  .language-selector__icon {
    margin-right: 17px;
  }
  .language-selector__icon,
  .language-selector__icon svg {
    display: block;
    width: 18px;
    height: 18px;
  }
  .language-selector__icon path {
    fill: var(--primary-two);
  }

  .header__top-bar {
    position: relative;
    transition: height var(--transition-mode) var(--transition-speed);
    z-index: 10;

    .header-alert,
    .terminal-alert {
      display: block;
    }

    .message__icon {
      margin-top: 11px;
    }
    .message__text {
      padding-top: 7px;
      padding-bottom: 8px;
    }

    .terminal-alert__message-text {
      display: flex;
      max-width: calc(100vw - 113px);
    }

    .terminal-alert__short,
    .terminal-alert__short p {
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .terminal-alert__short {
      flex: 1;
      margin-right: 13px;
      min-height: 2.5rem;
      max-height: 154px;
      overflow: auto;
    }
  }
}

.header--alert-open {
  height: 255px;

  .terminal-alert {
    height: 105px;
    transition: height var(--transition-speed) var(--transition-mode);
  }
}

.header--alert-open .terminal-alert__expand {
  display: none;
}

.header--alert-open .terminal-alert__short,
.header--alert-open .terminal-alert__short p {
  white-space: normal !important;
}

.header--alert-open .terminal-alert__short {
  height: 90px;
}

@media (max-width: 1024px) {
  .terminal-selector {
    height: 60px !important;
    position: fixed;
    top: 0;
    margin: 0;
    width: 100%;
    .navigation {
      max-height: calc(100vh - 375px - 115px) !important;
    }
    &.header--alert {
      height: 120px !important;
      .navigation {
        max-height: calc(100vh - 375px - 115px - 60px) !important;
      }
      .navigation__text-helper {
        top: calc(100vh - 56px - 115px - 60px) !important;
      }
      .header__main-bar--terminal {
        .header__group {
          top: 60px;
        }
      }
      .navigation--language {
        top: calc(100vh - 168px - 115px) !important;
        max-height: calc(100vh - 375px - 115px - 60px) !important;
      }
    }
    .header__main-bar,
    .header__middle-bar {
      padding: 0 20px;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
        box-shadow: 0 2px 15px 0 rgba(197, 197, 197, 0.5);
      }
    }

    .header__middle-bar {
      z-index: 2;
      background-color: #ffffff;
    }

    .header__main-bar--terminal {
      position: absolute;
      left: -100%;
      height: 0;
      .header__logo {
        position: absolute;
        height: 24px;
        top: 215px;
        width: 160px;
        text-align: center;
        transition-duration: 0.5s;
        &-link {
          margin-left: 0;
        }
      }
      .navigation--language {
        position: absolute;
        width: calc(100% - 40px);
        height: 54px;
        left: 20px;
        max-height: calc(100vh - 375px - 115px) !important;
        overflow-y: auto;
        top: calc(100vh - 108px - 115px);
        -webkit-overflow-scrolling: touch;
        z-index: 1;
        transition: all var(--transition-mode) var(--transition-speed);
        &.navigation--active {
          max-height: calc(100vh - 325px - 115px) !important;
          top: 263px !important;
          height: 100vh;
          background: #fff;
          .navigation__arrow-icon{
            transform: rotate(-180deg);
          }
        }
        .secondary-navigation__link{
          pointer-events: all!important;
        }
      }
      .navigation--terminal {
        position: absolute;
        width: calc(100% - 40px);
        left: 20px;
        top: 263px;
        max-height: calc(100vh - 375px - 50px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transition: all var(--transition-mode) var(--transition-speed);
      }
      .navigation--terminal,
      .navigation--language {
        .secondary-navigation {
          position: relative;
          left: 0;
          width: 100%;
          z-index: 1;
          top: auto;
          padding: 0;
          opacity: 1;
          box-shadow: none !important;
          transform: none !important;
          background: none;
          &::before {
            display: none;
          }
          &::after {
            top: 0;
          }
          .secondary-navigation__list {
            padding: 20px 30px;
            margin-top: 14px;
            opacity: 0;
            transform: translateY(-20px);
            transition: all var(--transition-mode) 0.4s;
          }
        }
        .navigation__item--highlight {
          margin-left: 0 !important;
          &:first-child {
            width: 100%;
            padding: 14px;
            margin: 0;
            border-top: 0;
          }
        }
        .navigation__link--active {
          &::after {
            display: none;
          }
        }
      }
      .navigation--language{
        .navigation__link--active{
          &::after {
            display: block!important;
          }
        }
      }
      .header__logo-link--apm {
        display: inline-flex;
        position: absolute;
        top: 0;
        left: -50%;
        right: -50%;
        margin: 0 auto;
        opacity: 0;
        transform: translateX(100px);
        z-index: -1;
      }
    }
  }
  .header--show-navigation-global {
    .navigation--global {
      transform: translate(0) !important;
      opacity: 1 !important;
    }
    .navigation--terminal,
    .navigation--language {
      opacity: 0 !important;
      -webkit-transform: translateX(-100%) !important;
      z-index: -1 !important;
    }
  }

  .header__top-bar {
    .message__text {
      padding-top: 17px !important;
      padding-bottom: 18px !important;
    }

    .terminal-alert__message-text {
      max-width: calc(100vw - 96px) !important;
    }

    .terminal-alert__short {
      height: auto;
      line-height: inherit;
    }

    .message__icon {
      margin-top: 20px !important;
    }
  }
}
[dir="rtl"] .navigation__toggle-icon {
  margin-right: 0;
  margin-left: 10px;
}
[dir="rtl"] .navigation__item {
  margin-left: 38px;
  margin-right: 0;
}
[dir="rtl"] .header--standard .navigation--dropdown .secondary-navigation,
[dir="rtl"] .header--large .navigation--dropdown .secondary-navigation,
[dir="rtl"] .header--act .navigation--dropdown .secondary-navigation{
  left: auto;
  right: -18px;
}

[dir="rtl"] .secondary-navigation__link:hover,
[dir="rtl"] .secondary-navigation__link:focus {
  padding: 0 20px 0 0;
  margin: 0 0 0 -20px;
}
[dir="rtl"] .secondary-navigation__link::before {
  right: 0;
  left: auto;
}
[dir="rtl"] .navigation__item:first-child {
  margin-right: 38px;
}
[dir="rtl"] .navigation__link {
  text-align: right;
}
[dir="rtl"] .navigation__link-icon {
  margin-right: auto;
  margin-left: 10px;
}
[dir="rtl"] .secondary-navigation__item {
  transform: translateX(-80px);
  padding-right: 0;
  padding-left: 40px;
}
[dir="rtl"] .secondary-navigation__item:nth-child(4n + 4) {
  transition-delay: 0.05s;
}
[dir="rtl"] .secondary-navigation__item:nth-child(4n + 3) {
  transition-delay: 0.1s;
}
[dir="rtl"] .secondary-navigation__item:nth-child(4n + 2) {
  transition-delay: 0.15s;
}
[dir="rtl"] .secondary-navigation__item--visible {
  opacity: 1;
  transform: translateX(0);
}
[dir="rtl"]
  .header--standard
  .navigation--dropdown
  .secondary-navigation--reverse,
[dir="rtl"]
  .header--large
  .navigation--dropdown
  .secondary-navigation--reverse,
[dir="rtl"]
  .header--act
  .navigation--dropdown
  .secondary-navigation--reverse {
  left: -18px;
  right: auto;
}
[dir="rtl"] .navigation--dropdown .secondary-navigation::before{
  left: auto;
  right: 31px;
}
[dir="rtl"] .navigation--dropdown .secondary-navigation--reverse::before {
  left: 31px;
  right: auto;
}
[dir="rtl"] .navigation--dropdown .secondary-navigation__item {
  transition: none;
  transform: none;
}
[dir="rtl"] .navigation__toggle {
  margin: 0 20px 0 -2px;
}
[dir="rtl"] .tertiary-navigation__link:hover,
[dir="rtl"] .tertiary-navigation__link:focus {
  transform: translateX(-20px);
}
[dir="rtl"] .tertiary-navigation__link::before {
  right: auto;
  left: calc(100% + 10px);
}
[dir="rtl"] .tertiary-navigation {
  left: auto;
  right: -18px;
}
[dir="rtl"] .tertiary-navigation::before {
  left: auto;
  right: 31px;
}
@media screen and (max-width: 1400px) {
  [dir="rtl"] .navigation__item {
    margin-left: 20px;
  }
  [dir="rtl"] .navigation__item:first-child {
    margin-right: 20px;
  }
}
[dir="rtl"] .search-bar {
  margin-right: 0;
  margin-left: 48px;
}
[dir="rtl"] .search-bar__button-wrap {
  left: auto;
  right: calc(100% + 50px);
}
[dir="rtl"] .search-bar__form {
  right: auto;
  left: 0;
}
[dir="rtl"] .header__logo, 
[dir="rtl"] .main__logo,
[dir="rtl"] .main__logo:focus,
[dir="rtl"] .header__logo:focus {
  margin: 0 30px 0 auto!important;
}
[dir="rtl"] .language-selector__icon,
[dir="rtl"] .login-header__icon {
  margin-right: 0;
  margin-left: 17px;
}
[dir="rtl"] .search-bar__hide {
  right: auto;
  left: -26px;
}

@media screen and (max-width:1024px) {
  [dir="rtl"] .language-selector__icon {
    margin-right: 0;
    margin-left: 17px;
  }
  [dir="rtl"] .search-bar {
    margin-right: auto;
    margin-left: auto;
    left: 0;
    right: 0;
  }
  [dir="rtl"] .navigation__arrow-icon {
    left: 17px;
    right: auto;
  }
  [dir="rtl"] 
  .terminal-selector 
  .navigation--dropdown .navigation__item{
    margin: 0px!important;
  }
}