.footer {
  font-family: var(--maersk);
  width: 100%;
  color: #ffffff;
  background-color: var(--mid-grey);
  background-position: top right;
  background-repeat: no-repeat;
  background-image: url(../../images/footer-background.svg);

  a {
    color: #ffffff;
  }

  &__logo,
  &__logo svg,
  &__logo img {
    height: 70px;
    width: auto;
    max-width: 100%;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      margin-bottom: 30px;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 600px) {
      margin: 0 auto;
    }
  }

  &__social a {
    margin-left: 32px;
    background: none;

    @media (max-width: 600px) {
      margin: 0 16px;
    }
  }

  &__top {
    position: relative;
    padding: 40px 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

    &-inner {
      display: flex;
      justify-content: space-between;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      h2 {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 22px;
      }
    }
  }

  &__card {
    width: var(--unit-eight);
    margin-top: 23px;
    border-top: 1px solid #ffffff;
    padding-top: 19px;

    @media (max-width: 600px) {
      width: var(--unit-twelve);
      text-align: center;
    }

    a {
      font-family: var(--maersk-bold);
    }

    &--description {
      line-height: 25px;
      font-size: 14px;
    }
  }

  &__quick-links {
    width: var(--unit-four);
    text-align: right;
    margin-top: 23px;
    border-top: 1px solid #ffffff;
    padding-top: 19px;

    @media (max-width: 600px) {
      width: var(--unit-twelve);
      text-align: center;
    }

    ul {
      list-style: none;
    }
  }

  &__quick-link {
    margin-bottom: 6px;

    a {
      text-decoration: none;
      color: white !important;
      font-weight: bold;
      font-size: 14px;
      display: inline-block;
      background: none;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

.footer-terms {
  padding: 25px 0;
  display: flex;
  background-color: var(--primary-three);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: white;
  font-family: var(--maersk-bold);
  font-size: 14px;

  &__bottom-inner {
    display: flex;
    justify-content: center;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }

  .wrapper {
    margin: 0 auto;
    width: var(--wrapper-width);
    padding: 0 var(--wrapper-margin-wide);
    max-width: 100%;

    @media (max-width: 600px) {
      padding: 0 var(--wrapper-margin-narrow);
    }
  }

  &_title {
    margin: 0 0 25px;
    font-weight: var(--maersk-bold);

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  &_links {
    display: flex;
    margin-left: 20px;

    @media (max-width: 600px) {
      margin-left: 0px;
    }

    a {
      text-decoration: none;
      padding: 0px 10px;
      color: white !important;
      font-weight: var(--maersk-bold);
      background: none;
      display: block !important;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}
