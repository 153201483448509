$sso_param: "";
@import "../../../node_modules/@maersk-global/icons/fonts/web/20px/icons.min.css";

@import "./header.scss";
@import "./footer.scss";
@import "./components/header-nav.scss";
@import "./components/header-terminal-selector.scss";
@import "./components/terminal-nav-header.scss";

body {
  margin: 0;
}

main {
  margin-top: 90px;
}

.header--large ~ main {
  margin-top: 150px;
}

@media screen and (max-width: 1024px) {
  .header ~ #main, .header--large ~ #main, .header--large.header--slim ~ #main {
      margin-top: 60px;
  }
}

@import "./utils/normalise.css";
@import "./utils/variables.css";
@import "./utils/theming.scss";

@font-face {
  font-family: "MaerskText-Regular";
  src: url('./utils/fonts/MaerskText-Regular.woff2#{$sso_param}') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "MaerskText-Light";
  src: url('./utils/fonts/MaerskText-Light.woff2#{$sso_param}') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "MaerskText-Bold";
  src: url("./utils/fonts/MaerskText-Bold.woff2#{$sso_param}") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
