:root {
    /* grid system config */
    --wrapper-width: 1664px;
    --wrapper-margin-wide: 64px;
    --wrapper-margin-narrow: 32px;

    /* fonts */
    --maersk: "MaerskText-Regular", helvetica, sans-serif;
    --maersk-light: "MaerskText-Light", helvetica, sans-serif;
    --maersk-bold: "MaerskText-Bold", helvetica, sans-serif;
    --helveticaneue: "helveticaneue", helvetica, sans-serif;

    /* transition properties */
    --transition-speed: 0.4s;
    --transition-mode: cubic-bezier(0.645, 0.045, 0.355, 1);

    /* colors */
    --primary-one: #3c3c46;
    --primary-two: #ff6441;
    --primary-three: #0a6e80;
    --primary-four: #eeaf30;

    --primary-two-muted: #ff8266;
    --primary-two-shade: #f25e18;
    --primary-two-hint: #ffefec;

    --primary-three-hint: #e6f1f2;

    --maersk-blue: #42b0d5;

    --red: #e21f2d;
    --red-hint: #fdedee;

    --soft-yellow: #f0be78;
    --soft-yellow-hint: #f6f2e4;

    --green: #008040;
    --green-hint: #eafaf1;

    --neutral-grey: #a3adbc;
    --neutral-grey-hint: #eef0f3;

    --dark-grey: #454545;
    --mid-grey: #5a6e7d;
    --mid-grey-muted: #dfe4eb;
    --mid-grey-hint: #7a8591;
    --light-grey: #737373;

    --white: #d8d8d8;
    --white-two: #f7f7f7;
    --white-three: #f5f7fa;
    --white-four: #fafbfc;
    --pinkish-grey: #cbc7bf;
    --light-blue: #f0fafb;

    /* box shadows */
    --shadow-tilted: 0 2px 15px 0 rgba(197, 197, 197, 0.5);
    --shadow-tilted-active: 0 2px 30px 0 rgba(197, 197, 197, 0.5);
    --shadow-downlight: 0 0 15px 0 rgba(197, 197, 197, 0.5);
    --shadow-downlight-active: 0 0 30px 0 rgba(197, 197, 197, 0.5);

    /* icons */
    --search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23454545' fill-rule='nonzero' d='M8.5 14a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zm6.372-1.542l5.835 5.835-1.414 1.414-5.7-5.7a7.5 7.5 0 1 1 1.28-1.549z'/%3E%3C/svg%3E");
    --close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233c3c46' fill-rule='nonzero'%3E%3Cpath d='M10 8.191l5.186-4.8 1.414 1.31-6.6 6.108-6.707-6.207 1.414-1.31L10 8.193zm-5.293 7.417L3.293 14.3l6.6-6.108 6.707 6.207-1.414 1.31-5.293-4.9-5.186 4.8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    --tick: url("data:image/svg+xml,%3Csvg width='14' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.875 6.858L12.555 0l1.332 1.492-9.12 8.142L.5 5.188l1.443-1.385z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E");
    --calendar: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0a1 1 0 102 0h2a1 1 0 102 0h2a1 1 0 002 0h1a2 2 0 012 2v11.867C16 15.045 15.105 16 14 16H2c-1.105 0-2-.955-2-2.133V2a2 2 0 012-2zm12 4H1v9.923c0 .552.386 1.008.883 1.07L2 15h12c.513 0 .936-.416.993-.951l.007-.126V4zM7 5v4H2V5h5z' fill='%23ff6441' fill-rule='evenodd'/%3E%3C/svg%3E");
    --arrow-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='6' viewBox='0 0 12 6'%3E%3Cpath d='M6 6 0 0h12z' fill='%23ff6441' fill-rule='evenodd'/%3E%3C/svg%3E");
    --arrow-up: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='6' viewBox='0 0 12 6'%3E%3Cpath d='M6 0l6 6H0z' fill='%23ff6441' fill-rule='evenodd'/%3E%3C/svg%3E");
    --arrow-left: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='12' viewBox='0 0 6 12'%3E%3Cpath d='m0 6 6-6v12z' fill='%23FF6441' fill-rule='evenodd'/%3E%3C/svg%3E");
    --arrow-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='12' viewBox='0 0 6 12'%3E%3Cpath d='M6 6 0 0v12z' fill='%23FF6441' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    --arrow-left-white: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='12' viewBox='0 0 6 12'%3E%3Cpath d='m0 6 6-6v12z' fill='%23FFFFFF' fill-rule='evenodd'/%3E%3C/svg%3E");
    --arrow-right-white: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='12' viewBox='0 0 6 12'%3E%3Cpath d='M6 6 0 0v12z' fill='%23FFFFFF' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    --go: url("data:image/svg+xml,%3Csvg width='4' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 8l4-4-4-4v1.403L2.585 4 0 6.58z' fill='%23ff6441' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    --go-fill: url("data:image/svg+xml,%3Csvg width='4' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 8l4-4-4-4z' fill='%23ff6441' fill-rule='evenodd'/%3E%3C/svg%3E");
}
