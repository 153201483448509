/* normalise 5.0.0 */
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
[hidden],
audio:not([controls]) {
  display: none;
}
html {
  font-size: 62.5%;
  text-size-adjust: 100%;
}
button,
html,
input,
select,
textarea {
  font-family: inherit;
}
body {
  margin: 0;
  line-height: 1.4;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: 1em;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: none;
}
q:after,
q:before {
  content: "";
  content: none;
}
small {
  font-size: 85%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
nav ol,
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
svg:not(:root) {
  overflow: hidden;
}
fieldset,
figure,
form {
  border: 0;
  margin: 0;
  padding: 0;
}
label[for] {
  cursor: pointer;
}
legend {
  border: 0;
  padding: 0;
  white-space: normal;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  border-radius: 0;
}
td,
textarea {
  vertical-align: top;
}
button,
input {
  line-height: normal;
}
button,
input[type="button"],
input[type="reset], input[type=submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
a:active,
a:hover,
input,
input:focus,
select,
select:focus,
textarea,
textarea:focus {
  outline: 0;
}
.cf:after,
.cf:before {
  content: "";
  display: table;
}
.cf:after {
  clear: both;
}
