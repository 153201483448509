.terminal-selector__show-button {
  height: 22px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 18px 22px;
  color: var(--primary-two);
  padding-left: 32px;
  white-space: nowrap;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14' height='18' viewBox='0 0 18 23'%3E%3Cpath d='M9 21c5.333-4.619 8-8.478 8-11.579C17 4.771 13.418 1 9 1S1 4.77 1 9.421c0 3.1 2.667 6.96 8 11.579z' fill='%23FFEFEC' stroke='%23FF6441' stroke-width='2'%3E%3C/path%3E%3C/svg%3E");
}
[dir="rtl"] .terminal-selector__show-button {
  background-position: right center;
  padding-left: 0;
  padding-right: 32px;
}

.terminal-selector__menu {
  position: fixed;
  z-index: 3;
  opacity: 0;
  height: 100vh;
  width: 100%;
  padding-top: 15vh;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background: #ffffff;
  transform: translateY(-100vh);
  transition: opacity var(--transition-mode) var(--transition-speed);
}
.terminal-selector--open .terminal-selector__menu {
  opacity: 1;
  transform: translateY(0);
}

.terminal-selector__close-button {
  position: absolute !important;
  right: 25px;
  top: 25px;
}
[dir="rtl"] .terminal-selector__close-button {
  right: auto;
  left: 25px;
}

.terminal-selector__header {
  position: absolute;
  top: 0;
  height: 90px;
  display: flex;
  align-items: center;
}
.terminal-selector .header__logo {
  position: static;
}
.terminal-selector__tagline {
  margin-left: 30px;
  opacity: 0;
  transform: translateX(25vw);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 18px 22px;
  color: var(--primary-two);
  padding-left: 32px;
  transition: opacity var(--transition-mode) var(--transition-speed),
    transform var(--transition-mode) var(--transition-speed);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14' height='18' viewBox='0 0 18 23'%3E%3Cpath d='M9 21c5.333-4.619 8-8.478 8-11.579C17 4.771 13.418 1 9 1S1 4.77 1 9.421c0 3.1 2.667 6.96 8 11.579z' fill='%23FF6441' fill-opacity='0.5' stroke='%23FF6441' stroke-width='2'%3E%3C/path%3E%3C/svg%3E");
}
[dir="rtl"] .terminal-selector__tagline {
  margin-left: 0;
  margin-right: 30px;
  background-position: right center;
  padding-left: 0;
  padding-right: 32px;
  transform: translateX(-25vw);
}
.terminal-selector--open .terminal-selector__tagline {
  transform: translateX(0);
  opacity: 1;
}

.terminal-selector__inner {
  width: 100%;
  text-align: center;
  padding-bottom: 15vh;
  min-height: 85vh;
}

.terminal-selector__heading {
  font-size: 3.2rem;
  line-height: 4.3rem;
  color: var(--dark-grey);
}

.terminal-selector__search {
  position: relative;
  margin: 52px auto 76px;
  max-width: 600px;
}
.terminal-selector__autocomplete {
  border: 2px solid var(--white);
  padding: 0 10px;
  width: 100%;
  height: 50px;
  background-image: var(--search);
  background-position: center right 20px;
  background-repeat: no-repeat;
}

.terminal-list {
  top: 48px;
  left: 0px;
  width: 100%;
  position: absolute;
  display: none;
  z-index: 1;
  background-color: white;
}

[dir="rtl"] .terminal-selector__autocomplete {
  background-position: center left 20px;
}

.terminal-selector__search .ui-autocomplete.ui-widget.ui-widget-content {
  top: calc(100% - 1px) !important;
  max-height: 50vh;
  overflow-y: auto;
  max-width: none;
  text-align: left;
  box-shadow: 0 0 15px 0 rgba(197, 197, 197, 0.5);
  border: 0;
}
[dir="rtl"]
  .terminal-selector__search
  .ui-autocomplete.ui-widget.ui-widget-content {
  text-align: right;
}
.terminal-selector__search .ui-menu .ui-menu-item {
  position: relative;
  overflow: hidden;
  color: var(--mid-grey);
  font-size: 1.4rem;
  line-height: 2rem;
}
.terminal-selector__search .ui-menu .ui-menu-item-wrapper {
  display: block;
  padding: 10px 16px;
}
.terminal-selector__search .ui-state-active,
.terminal-selector__search .ui-widget-content .ui-state-active {
  border-radius: 0;
  color: var(--primary-one);
  background: var(--primary-two-hint);
  border: 0;
}

.terminal-selector__list {
  width: 100%;
}

.terminal-selector__back {
  display: none;
}

.terminal-selector__breadcrumb {
  width: 800px;
  margin: 0 auto 9px;
}

.terminal-selector__button {
  width: 25%;
  padding: 0 20px;
  text-align: left;
  transition: color var(--transition-mode) var(--transition-speed);
}
[dir="rtl"] .terminal-selector__button {
  text-align: right;
}
.terminal-selector__button:hover,
.terminal-selector__button:focus {
  color: var(--primary-two);
}

.terminal-selector__button--breadcrumb {
  width: auto;
  line-height: 3rem;
  padding: 0;
  color: var(--primary-one);
  font-size: 1.8rem;
  margin-right: 10px;
  cursor: pointer;
}
[dir="rtl"] .terminal-selector__button--breadcrumb {
  margin-right: 0;
  margin-left: 10px;
}
.terminal-selector__button--highlight {
  background: var(--white-two);
  padding: 0 10px;
  font-size: 1.6rem;
}

.terminal-selector__button--breadcrumb.terminal-selector__button--region,
.terminal-selector__button--breadcrumb.terminal-selector__button--country {
  display: none;
}

.terminal-selector__breadcrumb,
.terminal-selector__regions,
.terminal-selector__countries,
.terminal-selector__services {
  display: none;
}
.terminal-selector__breadcrumb--show,
.terminal-selector__regions--show,
.terminal-selector__countries--show,
.terminal-selector__services--show {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.terminal-selector__regions {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  flex-direction: column;
}
.terminal-selector__regions .terminal-selector__button {
  width: 100%;
  padding: 15px 10px 11px;
  font-size: 1.8rem;
  line-height: 3.2rem;
  color: var(--primary-one);
  border-bottom: 2px solid var(--white-two);
}
.terminal-selector__regions .terminal-selector__button:hover,
.terminal-selector__regions .terminal-selector__button:focus {
  position: relative;
  background: var(--white-two);
  padding-right: 35px;
}
.terminal-selector__regions .terminal-selector__button:hover::after,
.terminal-selector__regions .terminal-selector__button:focus::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='9' height='14' viewBox='0 0 9 14'%3E%3Cg transform='rotate(-90 7 7)'%3E%3Cpath fill='%233c3c46' fill-rule='nonzero' d='M7 5.586L12.186.4 13.6 1.814 7 8.414.293 1.707 1.707.293z'/%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 14px;
}
[dir="rtl"] .terminal-selector__regions .terminal-selector__button:hover,
[dir="rtl"] .terminal-selector__regions .terminal-selector__button:focus {
  padding-left: 35px;
  padding-right: 10px;
}
[dir="rtl"] .terminal-selector__regions .terminal-selector__button:hover::after,
[dir="rtl"]
  .terminal-selector__regions
  .terminal-selector__button:focus::after {
  right: auto;
  left: 25px;
  transform: translateY(-50%) rotate(180deg);
  transform-origin: center;
}
.terminal-selector__countries,
.terminal-selector__services {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 23px;
  border-top: 2px solid var(--white-two);
}
.terminal-selector__type {
  width: 50%;
  text-align: left;
  padding: 0 53px;
  margin-top: 18px;
  background-position: left 23px top;
  background-repeat: no-repeat;
  background-size: 18px 22.3px;
}
[dir="rtl"] .terminal-selector__type {
  text-align: right;
  background-position: right 23px top;
}
.terminal-selector__type--terminal {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14' height='18' viewBox='0 0 18 23'%3E%3Cpath d='M9 21c5.333-4.619 8-8.478 8-11.579C17 4.771 13.418 1 9 1S1 4.77 1 9.421c0 3.1 2.667 6.96 8 11.579z' fill='%233c3c46' fill-opacity='0.5' stroke='%23004165' stroke-width='2'%3E%3C/path%3E%3C/svg%3E");
}
.terminal-selector__type--inland-service {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14' height='18' viewBox='0 0 18 23'%3E%3Cpath d='M9 21c5.333-4.619 8-8.478 8-11.579C17 4.771 13.418 1 9 1S1 4.77 1 9.421c0 3.1 2.667 6.96 8 11.579z' fill='%233CB6CE' fill-opacity='0.5' stroke='%233CB6CE' stroke-width='2'%3E%3C/path%3E%3C/svg%3E");
}

.terminal-selector__type-title {
  color: var(--primary-one);
  font-size: 1.8rem;
  margin-bottom: 19px;
}
.terminal-selector__countries .terminal-selector__button,
.terminal-selector__link,
.terminal-selector__link:active,
.terminal-selector__link:hover,
.terminal-selector__link:focus {
  display: block;
  margin: 18px 0 0;
  padding: 0;
  color: var(--light-grey);
  font-family: var(--maersk);
  background-color: transparent;
}
.terminal-selector__link:hover,
.terminal-selector__link:focus,
.terminal-selector__link:active {
  color: var(--primary-two);
  text-decoration: none;
}

@media screen and (max-width: 1280px) {
  .terminal-selector__show-button {
    font-size: 1.4rem;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 1024px) {
  .terminal-selector {
    position: absolute;
    top: 135px;
    width: calc(100% - 40px);
    margin: 11px 20px 0;
    z-index: 3 !important;
  }

  .terminal-selector__show-button,
  [dir="rtl"] .terminal-selector__show-button {
    width: 100%;
    height: 50px;
    padding: 0;
    margin: 0;
    background: var(--primary-two);
    color: #ffffff;
  }

  .terminal-selector__menu {
    position: absolute;
    top: -15px;
    z-index: 1;
    width: calc(100% + 40px);
    height: calc(100vh - 70px);
    margin-left: -20px;
    padding: 0;
    overflow: hidden;
    box-shadow: none;
    opacity: 0;
    transform: translateY(100%);
    transition: all var(--transition-mode) var(--transition-speed);
  }

  .terminal-selector__close-button {
    top: 0;
    right: 12px;
    padding: 20px;
  }
  .terminal-selector__close-button::before,
  .terminal-selector__close-button::after {
    width: 16px;
  }

  .terminal-selector__header {
    display: none;
  }

  .terminal-selector__heading {
    font-size: 2.2rem;
    line-height: 3.4rem;
    margin-top: 46px;
  }

  .terminal-selector__inner {
    padding: 0 20px;
    min-height: 0;
    height: calc(100vh - 116px);
  }

  .terminal-selector__search {
    margin: 34px auto 20px;
  }

  .terminal-selector__type-title {
    font-size: 1.6rem;
  }
  .terminal-selector__link {
    font-size: 1.4rem;
  }

  .terminal-selector__back {
    position: relative;
    text-align: left;
    margin: 0 auto 27px;
    width: calc(100% - 60px);
    font-size: 1.6rem;
    line-height: 3rem;
    color: var(--primary-two);
  }
  [dir="rtl"] .terminal-selector__back {
    text-align: right;
  }
  .terminal-selector__back::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13' height='22' viewBox='0 0 13 22'%3E%3Cg transform='rotate(90 6.5 6.5)'%3E%3Cpath fill='%23ff6441' fill-rule='nonzero' d='M20.293.473l1.414 1.414L11.09 12.504.293 1.707 1.707.293l9.383 9.383z'/%3E%3C/g%3E%3C/svg%3E");
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    height: 22px;
  }
  [dir="rtl"] .terminal-selector__back::before {
    left: auto;
    right: -25px;
    transform: translateY(-50%) rotate(180deg);
    transform-origin: center;
  }
  .terminal-selector__back--show {
    display: block;
  }

  .terminal-selector__breadcrumb,
  .terminal-selector__regions,
  .terminal-selector__countries,
  .terminal-selector__services {
    width: calc(100% - 16px);
  }

  .terminal-selector__breadcrumb {
    padding-bottom: 13px;
    border-bottom: 2px solid var(--white-two);
  }
  .terminal-selector__regions,
  .terminal-selector__countries,
  .terminal-selector__services {
    padding: 0 20px;
    overflow-y: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: calc(100vh - 270px);
    border-top: none;
  }
  .ios .terminal-selector__regions,
  .ios .terminal-selector__countries,
  .ios .terminal-selector__services {
    flex-wrap: unset;
    flex-flow: row wrap;
  }
  .terminal-selector__countries,
  .terminal-selector__services {
    max-height: calc(100vh - 380px);
  }
  .header--alert .terminal-selector__countries,
  .header--alert .terminal-selector__services {
    max-height: calc(100vh - 380px - 60px);
  }
  .ios .terminal-selector__regions {
    max-height: calc(100vh - 385px);
  }
  .ios .header--alert .terminal-selector__regions {
    max-height: calc(100vh - 385px - 60px);
  }
  .android .terminal-selector__regions {
    max-height: calc(100vh - 320px);
  }
  .android .header--alert .terminal-selector__regions {
    max-height: calc(100vh - 320px - 60px);
  }
  .ios .terminal-selector__countries,
  .ios .terminal-selector__services {
    max-height: calc(100vh - 495px);
  }
  .ios .header--alert .terminal-selector__countries,
  .ios .header--alert .terminal-selector__services {
    max-height: calc(100vh - 495px - 60px);
  }
  .android .terminal-selector__countries,
  .android .terminal-selector__services {
    max-height: calc(100vh - 430px);
  }
  .android .header--alert .terminal-selector__countries,
  .android .header--alert .terminal-selector__services {
    max-height: calc(100vh - 430px - 60px);
  }

  .terminal-selector__type {
    width: 100%;
    padding: 0 0 0 34px;
    margin-top: 27px;
    background-position: left top;
  }
  [dir="rtl"] .terminal-selector__type {
    padding: 0 34px 0 0;
    background-position: right top;
  }

  .terminal-selector__regions .terminal-selector__button,
  .terminal-selector__regions .terminal-selector__button:hover,
  .terminal-selector__regions .terminal-selector__button:focus {
    position: relative;
    font-size: 1.6rem;
    line-height: 3rem;
    padding: 16px 15px 16px 0;
  }
  [dir="rtl"] .terminal-selector__regions .terminal-selector__button,
  [dir="rtl"] .terminal-selector__regions .terminal-selector__button:hover,
  [dir="rtl"] .terminal-selector__regions .terminal-selector__button:focus {
    padding: 16px 15px 16px 0;
  }
  .terminal-selector__regions .terminal-selector__button::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='9' height='14' viewBox='0 0 9 14'%3E%3Cg transform='rotate(-90 7 7)'%3E%3Cpath fill='%23454545' fill-rule='nonzero' d='M7 5.586L12.186.4 13.6 1.814 7 8.414.293 1.707 1.707.293z'/%3E%3C/g%3E%3C/svg%3E");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    line-height: 14px;
  }
  [dir="rtl"] .terminal-selector__regions .terminal-selector__button::after {
    left: 0;
    right: auto;
    transform: translateY(-50%) rotate(180deg);
    transform-origin: center;
  }
  .terminal-selector__countries .terminal-selector__button {
    position: relative;
    width: 100%;
    padding: 13px 15px 16px 0;
    border-bottom: 2px solid var(--white-two);
    margin: 0;
  }
  [dir="rtl"] .terminal-selector__countries .terminal-selector__button {
    padding: 13px 0 16px 15px;
  }
  .terminal-selector__countries .terminal-selector__button::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='9' height='14' viewBox='0 0 9 14'%3E%3Cg transform='rotate(-90 7 7)'%3E%3Cpath fill='%23454545' fill-rule='nonzero' d='M7 5.586L12.186.4 13.6 1.814 7 8.414.293 1.707 1.707.293z'/%3E%3C/g%3E%3C/svg%3E");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    line-height: 14px;
  }
  [dir="rtl"] .terminal-selector__countries .terminal-selector__button::after {
    left: 0;
    right: auto;
    transform: translateY(-50%) rotate(180deg);
    transform-origin: center;
  }

  .terminal-selector__button--highlight {
    display: none !important;
  }
  .terminal-selector__button--breadcrumb {
    margin: auto;
    font-size: 2.2rem;
    line-height: 2.4rem;
    text-align: center;
  }
}

.breadcrum-icon {
  display: flex;
  vertical-align: middle;
  align-items: center;
  font-weight: bold;
}
[dir="rtl"] .breadcrum-icon{
  margin-left: 12px;
  transform: rotate(180deg);
}
[dir="rtl"] .terminal-selector__regions .terminal-selector__button{
  &::after{
    transform: translateY(-50%) rotate(0deg)!important;
  } 
}
